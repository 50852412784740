/**
 * OVERVIEW
 *
 * IMPORTS
 * reset, vars, mixins, core, plugins
 *
 * LAYOUT
 * Helpers
 * Header / Main / Footer
 * Components
 * Pages (page overrides)
 *
 * Hooks (IE, ...)
 * Print
 */

/**
 * GUIDELINES
 * CLASSES
 * Naming: BEM - http://getbem.com/
 * Abbreviations: Emmet - http://docs.emmet.io/cheat-sheet/ | example: .va-m
 *
 * MEDIAQUERIES
 * @media $small, $medium, $maxMedium, $large, $larger, $huge, $massive
 */

/* =============================================================================
   IMPORTS
   ========================================================================== */

// CSS Reset
@import "inc/reset";

// Core
@import "inc/vars";
@import "inc/mixins";
//@import "inc/animations";
//@import "inc/custom-animations";

// Styled patterns and elements
@import "inc/grid";
@import "inc/type";
@import "inc/tables";
@import "inc/forms";
@import "inc/visual";
@import "plugins/modaal";
@import "plugins/slick";
@import "plugins/select2";

// Account
@import "inc/account-card";
@import "inc/account-nav";
@import "inc/account-tab";

// Plugins
@import "plugins/validation-engine";
@import "plugins/select-theme-default";
@import "plugins/aos";
//@import "plugins/fancybox";
@import "plugins/videojs";
@import "plugins/lightgallery";
@import "plugins/plyr";

/* =============================================================================
   LAYOUT
   ========================================================================== */

html {
  scroll-behavior: smooth;
  font-size: 62.5%;
  height: 100%;
}

body {
  @include default-font;
  color: $black;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  tap-highlight-color: rgba(0, 0, 0, 0);
  background-color: $white;
}

.page-wrap {
  position: relative;

  @media #{$medium} {
    min-height: calc(100vh - 390px);
    // overflow-x: hidden;
  }
}

.container {
  position: relative;
  max-width: 1550px;
  margin: 0 auto;
  padding: 0 20px;

  @media #{$larger} {
    padding-left: 30px;
    padding-right: 30px;
  }

  @media #{$huge} {
    padding-left: 40px;
    padding-right: 40px;
  }
}

.container--small {
  max-width: 980px;
}
.container--dots {
  background: $white;
  max-width: 1174px;
  padding-top: 30px;
  padding-bottom: 30px;
  margin-top: 60px;

  @media #{$larger} {
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 40px;
    padding-right: 40px;
  }

  @media #{$huge} {
    padding-top: 65px;
    padding-bottom: 65px;
    padding-left: 75px;
    padding-right: 75px;
  }
}

.container--body {
  max-width: 1174px;
}

.container--medium {
  max-width: 1480px;
}

.container--large {
  max-width: 1600px;
}

.container--offset {
  @media #{$medium} {
    margin-top: 45px;
  }

  @media #{$massive} {
    margin-top: 60px;
  }
}

.container--light {
  padding-top: 20px;
  padding-bottom: 20px;
  background: $white;

  @media #{$larger} {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  @media #{$huge} {
    padding-top: 65px;
    padding-bottom: 65px;
  }
}

.container--overlap {
  @media #{$medium} {
    margin-top: -210px;
  }
}

.container--overflow-hidden {
  overflow: hidden;
  display: block;
  position: relative;
}

.container-ovh {
  overflow: hidden;

  @media #{$large} {
    overflow: visible;
  }
}

.white-wrapper {
  background-color: #fff;
  margin-top: 50px;
  padding-top: 40px;
  @include clearfix;

  @media #{$medium} {
    padding-top: 90px;
  }
}

// Typekit async loading (all elements using $ typekit font)
.wf-loading {
  overflow: hidden;
  body {
    visibility: hidden;
  }
}

::selection {
  background-color: $selection;
  color: inherit;
}

/* =============================================================================
   HELPERS
   ========================================================================== */

// Icons
.icon {
  display: inline-block;
  vertical-align: middle;
  transition: $transitionDefault;
  fill: #000;
}

.icon-arrow {
  height: 12px;
  width: 10px;
}

.icon-arrow-down {
  height: 10px;
  width: 12px;
}

.icon-arrow-long-left,
.icon-arrow-long-right {
  width: 15px;
  height: 9px;
}

.icon-arrow-long-down {
  width: 9px;
  height: 15px;
}

.icon-close {
  width: 14px;
  height: 14px;
  fill: #1d1d1b;
}

.icon-check-mark {
  width: 16px;
  height: 11px;
}

.icon-mail {
  width: 21px;
  height: 16px;
  fill: $extraOrange;
}

.icon-phone {
  width: 20px;
  height: 20px;
  fill: $extraOrange;
}

.icon-marker {
  width: 15px;
  height: 21px;
  fill: $extraOrange;
}

.icon-search {
  width: 18px;
  height: 18px;
}

.icon-facebook {
  width: 10px;
  height: 16px;
}

.icon-twitter {
  width: 16px;
  height: 14px;
}

.icon-linkedin {
  width: 16px;
  height: 16px;
}

.icon-pinterest {
  width: 18px;
  height: 18px;
}

.icon-instagram {
  width: 19px;
  height: 19px;
}

.icon-youtube {
  width: 25px;
  height: 18px;
}

.icon-download {
  width: 15px;
  height: 14px;
}

.icon-img {
  width: 31px;
  height: 25px;
}

.icon-map {
  width: 18px;
  height: 25px;
}

.icon-print {
  width: 25px;
  height: 19px;
}

.icon-3d {
  width: 21px;
  height: 21px;
}

.icon-fullscreen,
.icon-floor-plan {
  width: 18px;
  height: 18px;
}

.icon-surface-full {
  width: 19px;
  height: 19px;
}

.icon-tag {
  width: 20px;
  height: 20px;
}

.icon-circle-arrow-down {
  width: 21px;
  height: 21px;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  border-radius: 100%;
  border: 1px solid $accent;
  line-height: 18px;

  .icon {
    width: 7px;
    height: 10px;
  }
}

.icon-play-arrow {
  width: 11px;
  height: 12px;
}

.icon-hamburger,
.icon-hamburger:before,
.icon-hamburger:after {
  position: absolute;
  right: 0;
  height: 1px;
  border-top: 2px solid $accent;
  transition: $transitionDefault;
}

.icon-hamburger {
  width: 15px;
  display: flex;
  justify-content: flex-end;

  &::before {
    width: 20px;
  }

  &::after {
    width: 10px;
  }
}

.icon-hamburger {
  &:before,
  &:after {
    content: "";
    margin-left: 0;
  }
  &:before {
    margin-top: 4px;
  }
  &:after {
    margin-top: 10px;
  }
}

.icon-account-home {
  width: 16px;
  height: 15px;
}

.icon-account-agenda {
  width: 14px;
  height: 16px;
}

.icon-account-agenda--large {
  width: 22px;
  height: 25px;
}

.icon-account-clicks {
  width: 7px;
  height: 12px;
}

.icon-account-communications {
  width: 18px;
  height: 14px;
}

.icon-account-communications--large {
  width: 22px;
  height: 18px;
}

.icon-account-document {
  width: 11px;
  height: 15px;
}

.icon-account-documents {
  width: 14px;
  height: 16px;
}

.icon-account-documents--large {
  width: 22px;
  height: 20px;
}

.icon-account-info {
  width: 16px;
  height: 16px;
}

.icon-account-info--large {
  width: 20px;
  height: 20px;
}

.icon-account-logout {
  width: 12px;
  height: 12px;
}

.icon-account-media {
  width: 16px;
  height: 14px;
}

.icon-account-media--large {
  width: 22px;
  height: 20px;
}

.icon-account-people {
  width: 17px;
  height: 12px;
}

.icon-account-visits {
  width: 16px;
  height: 10px;
}

.icon-account-contact {
  width: 16px;
  height: 10px;
}

.icon-account-checklist {
  width: 12px;
  height: 16px;
}

.icon-arrow-right {
  width: 10px;
  height: 10px;
}

// not available yet
.icon-account-history,
.icon-account-tasks {
  display: none;
}

.a-c {
  text-align: center;
}

.a-r,
.a-right {
  text-align: right;
}

// Exceptions: only add what you need!
.mb0.mb0 {
  margin-bottom: 0;
}
.mb15.mb15 {
  margin-bottom: 15px;
}
.mb20.mb20 {
  margin-bottom: 20px;
}
.mb30.mb30 {
  margin-bottom: 30px;
}
.mb40.mb40 {
  margin-bottom: 40px;
}
.mb60.mb60 {
  margin-bottom: 40px;

  @media #{$medium} {
    margin-bottom: 60px;
  }
}

.mb90.mb90 {
  margin-bottom: 40px;

  @media #{$medium} {
    margin-bottom: 90px;
  }
}

.mb150.mb150 {
  margin-bottom: 70px;

  @media #{$medium} {
    margin-bottom: 150px;
  }
}

.pb0.pb0 {
  padding-bottom: 0;
}

.mt0.mt0 {
  margin-top: 0;
}

.mt30.mt30 {
  margin-top: 30px;
}

// .mt40.mt40 { margin-top: 40px; }

// .ttu { text-transform: uppercase; }
// .ttl { text-transform: lowercase; }

// rte
.rte {
  margin-bottom: 20px;

  p {
    + h2,
    + h3 {
      margin-top: 3em;
    }
  }

  @media #{$medium} {
    margin-bottom: 60px;
  }
}

.rte--mb0 {
  margin-bottom: 0;

  & > *:last-child {
    margin-bottom: 0;
  }

  @media #{$medium} {
    margin-bottom: 0;
  }
}

.rte--large {
  @media #{$medium} {
    @include fs(22);
  }
}

.rte--gray {
  color: #323e4d;
  @include optimize-font;
}

.rte--width-small {
  width: 80%;
}

.rte--max-ch {
  max-width: 50ch;
}

.rte--columns {
  margin-bottom: 0;

  @media #{$large} {
    column-count: 2;
    column-gap: 60px;
  }
}

.rte--list-bullets {
  ul {
    list-style: none;
    padding-left: 0;

    li {
      font-size: 18px;
      position: relative;
      padding-left: 20px;
      padding-bottom: 8px;
      margin-bottom: 30px;
      border-bottom: 1px solid rgba($black, 0.4);

      &:before {
        content: "";
        display: block;
        position: absolute;
        top: 8px;
        left: 0;
        width: 10px;
        height: 10px;
        background-color: $accent;
        border-radius: 100%;
      }
    }
  }
}

.title--small {
  @include fluid-type(font-size, $mediumSize, $largerSize, 20px, 24px);
  font-weight: 800;
  margin-bottom: 0.8em;
  color: $black;
  padding: 70px 0 0;
}

.title--bold {
  font-weight: 700;
}

.title--mb-small {
  margin-bottom: 0.2em;
}

.title-large-icon {
  position: relative;
  padding: 70px 0 0;

  img {
    position: absolute;
    opacity: 0.2;
    top: 0;

    @media #{$larger} {
      left: -70px;
    }
  }
}

.title-large-icon--small {
  color: $accent;
  font-weight: 800;
  @include fs(20);
  padding: 0;
  margin-bottom: 25px;

  img {
    top: -20px;
  }
}

.rte-arrows-list {
  ul {
    margin-left: 0;
    list-style: none;
  }

  li {
    position: relative;
    padding-left: 20px;
    margin-bottom: 15px;

    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 8px;
      left: 0;
      width: 8px;
      height: 8px;
      background: url("../images/arrow-diagonal.svg") top left no-repeat;
    }
  }
}

// Google maps mw
.gm-style {
  img {
    max-width: inherit;
  }

  button {
    box-shadow: none;
    transform: none;
  }
}

// Responsive video
.video-container {
  position: relative;
  padding: 0 0 56.25%;
  height: 0;
  overflow: hidden;
  margin: 0 0 15px;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.issuu-container {
  @extend .video-container;
  margin-bottom: 40px;
  border-radius: $borderRadiusLarge;
  overflow: hidden;
}

// Equal height
.equal-h {
  display: flex;
  //overflow: hidden;
  flex-wrap: wrap;

  > * {
    display: flex;
  }

  &:before,
  &:after {
    display: none;
  }
}

html.no-flexbox {
  .equal-h {
    height: 100%;
    > div {
      float: left;
    }
  }
}

// Vertical align (not recommended)
// .va-wrap, .va-m, .va-b { display: table; width: 100%; height: 100%; }
// .va-wrap { table-layout: fixed; }
// .va-m, .va-b { display: table-cell; }
// .va-m { vertical-align: middle; }
// .va-b { vertical-align: bottom; }

// Block link
.block-link {
  position: relative;
  cursor: pointer;

  a:not(.block-link__target):not(.video-btn) {
    position: relative;
    z-index: 100;
  }
}

.block-link__target {
  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.table-row-link {
  cursor: pointer;
}

// more link
.more-link {
  text-decoration: none;
  @include fs(18);
  font-weight: 600;

  span {
    position: relative;
    transition: $transitionDefault;
    z-index: 5;

    &:before {
      display: inline-block;
      vertical-align: middle;
      content: "";
      height: 60px;
      width: 60px;
      background: #fff url("../images/arrow-right.svg") center no-repeat;
      background-size: 14px 14px;
      transition: $transitionDefault;
      margin: 0 -12px 0 -20px;
      border-radius: 100%;
      position: relative;
      top: -2px;
      z-index: -1;
      transition: $transitionDefault;
    }
  }

  &:hover,
  &:focus {
    span:before {
      transform: translateX(4px);
    }
  }

  &.more-link--orange {
    span {
      color: $extraOrange;

      &:before {
        background: #fff url("../images/arrow-orange.svg") center no-repeat;
      }
    }

    &:hover {
      span {
        color: $black;

        &:before {
          background: #fff url("../images/arrow-right.svg") center no-repeat;
        }
      }
    }
  }

  &.more-link--before {
    &:after {
      content: "";
      position: absolute;
      width: 100%;
      left: 0;
      top: 0;
      height: 100%;
      display: block;
      z-index: layer("base");
    }
  }
}

// scroll to
.scroll-to-link {
  text-decoration: none;

  .icon-circle-arrow-down {
    margin-right: 11px;
    position: relative;
    top: -1px;
    transition: $transitionDefault;
  }

  &:hover,
  &:focus {
    .icon-circle-arrow-down {
      transform: translateY(3px);
    }
  }
}

// cta
.cta {
  @extend .block-link;
  display: block;
  transition: $transitionDefault;
  color: $black;
  border-radius: $borderRadius;
  margin-bottom: 10px;

  &:not(.cta--no-link):not(.cta--no-transform):hover,
  &:not(.cta--no-link):not(.cta--no-tranform):focus {
    transform: translateY(-10px);
  }
}

.cta--no-link {
  cursor: auto;
}

.cta__visual {
  position: relative;
}

.cta__image {
  position: relative;
  height: 0;
  padding-bottom: 66.59%;
  background-color: #efefef;
  border-radius: 10px;
  overflow: hidden;

  img,
  .video-btn {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  img {
    height: 100%;
    object-fit: cover;
    font-family: "object-fit: cover;";
  }

  .cta__image--content {
    position: absolute;
    top: 0%;
    bottom: 0%;
    left: 0%;
    right: 0%;

    text-align: center;
    font-weight: 600;
    color: $white;

    width: 100%;
    height: 100%;
    background-color: rgba($extraOrange, 0.85);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: $gutter / 2;
    opacity: 0;
    transition: opacity $transitionDefault;
  }

  &:hover {
    cursor: pointer;

    .cta__image--content {
      opacity: 1;
    }
  }
}

.cta__header {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 100;
}

.cta__label {
  float: left;
  background-color: #000;
  color: #fff;
  border-radius: 10px;
  font-weight: 700;
  @include fs(16);
  padding: 5px 12px;
  margin: 0 0 10px 15px;
  text-transform: lowercase;

  @media #{$larger} {
    padding: 8px 15px;
  }
}

.cta__label--option,
.cta__label--contract {
  background-color: $extraOrange;
  color: #fff;
}

.cta__label--sold {
  background-color: $extraOrange;
  color: #fff;
}

.cta__label--new {
  background-color: #23cf5f;
  color: #fff;
}

.cta__content {
  @include clearfix;
  padding: 15px 100px 15px 0;

  @media #{$medium} {
    padding-right: 120px;
  }
}

.cta__title-wrap {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  // font-weight: 700;
}

.cta__title {
  @include fs(16);
  color: $black;
  margin-bottom: 0;
  line-height: 1.1em;
  word-wrap: break-word;
  hyphens: auto;

  small {
    font-weight: 400;
    @include fs(12);
  }

  strong {
    font-weight: 700;
  }
}

.properties-index .cta__title {
  @include fs(18);
  font-weight: 500;
}

.cta__subtitle--small {
  @include fs(14);
  margin-bottom: 0;
}

.cta__subtitle--small {
  @include fs(14);
  margin-bottom: 0;
}

.cta__subtitle--orange {
  @include fs(16);
  color: $extraOrange;
  margin-bottom: 0;
}

.text--orange {
  color: $extraOrange;
}

.cta__price {
  font-weight: 700;
  @include fs(16);
  line-height: 1.2em;
  position: absolute;
  right: 10px;
  bottom: -80px;
  width: 100px;
  height: 100px;
  background-color: $accent;
  border-radius: 100%;
  margin: 0;
  padding: 0 3px 0;
  text-align: center;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;

  small {
    @include fs(10);
    display: block;
    margin: 6px 0 0;
  }

  @media #{$medium} {
    width: 120px;
    height: 120px;
    @include fs(18);
    padding-top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    bottom: -90px;

    small {
      @include fs(12);
    }
  }

  @media #{$huge} {
    width: 130px;
    height: 130px;
    bottom: -60px;
  }
}

.cta__subtitle {
  @include fs(14);
  font-weight: 400;
}

.cta__target {
  @extend .block-link__target;
  color: $black;
  text-decoration: none;

  &:hover,
  &:focus {
    color: $accent;
  }
}

.cta__body {
  margin: 0;
  word-wrap: break-word;
}

.cta__content-footer {
  margin-top: 5px;

  p {
    margin: 5px 0 0;
  }

  @media #{$huge} {
    margin-top: 10px;
  }
}

.cta__contact {
  white-space: nowrap;
  display: inline-block;
  @include fs(14);
  font-weight: 600;
  margin-top: 10px;

  .icon-box {
    display: inline-block;
    margin-right: 10px;
  }

  .icon {
    fill: $extraOrange;
  }

  a {
    text-decoration: none;
    position: relative;
    z-index: 11;
    display: inline-block;
  }

  @media #{$large} {
    margin-top: 0;
  }
}

.cta__title--with-contact {
  @media #{$larger} {
    display: flex;
    justify-content: space-between;
    // flex-wrap: wrap;
    align-items: flex-end;
  }
}

.cta--office,
.cta--office-member {
  width: 100%;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  height: 100%;

  .grid {
    margin-bottom: 0;

    .grid__item {
      margin-bottom: 10px;

      @media #{$larger} {
        margin-bottom: 0;
      }
    }
  }

  p {
    margin-bottom: 0;
  }

  .cta__title {
    color: $black;
    @include fs(18);
    font-weight: 700;
    margin-bottom: 0.3em;

    small {
      @include fs(9);

      color: $extraOrange;
    }
  }

  .cta__subtitle {
    margin-bottom: 1em;
  }

  .cta__image {
    padding-bottom: 100%;
  }

  .cta__target {
    @include fs(18);
    display: block;
    margin-right: 5px;

    strong {
      @include fs(20);
      display: block;
      margin-bottom: 0px;
    }
  }

  .cta__content {
    background: $white;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    flex-direction: column;
    flex: 1;
  }
}

.cta__info {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.cta--highlight {
  color: #fff;

  .cta__content {
    background-color: $accent;
    @include fs(25);
    padding-left: 40px;
    padding-right: 40px;
    border-radius: 10px;
    min-height: 380px;
    transition: $transitionDefault;

    &:hover,
    &:focus,
    &:active {
      background-color: rgba($extraOrange, 0.9);
    }

    @media #{$medium} {
      min-height: 0;
    }
  }

  a {
    color: #fff;
  }

  @media #{$medium} {
    .cta__content {
      padding-left: 80px;
      padding-right: 80px;
    }
  }
}

.cta--flat {
  .cta__content {
    padding-left: 0;
    padding-right: 0;
    background-color: transparent;
  }
}

.cta__content--center {
  a {
    text-decoration: none;
  }

  .cta__content__cta-parted {
    @include display-font;
    // @include fs(18);
    font-weight: 800;
    @include optimize-font;
  }

  .cta-parted__black {
    color: $black;
  }

  .cta-parted__accent {
    color: #fff;
  }

  @include fs(18);
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
  text-align: center;

  .cta__content__body {
    margin-bottom: 5px;
  }

  p {
    margin-bottom: 0;
    line-height: 1.3em;

    + p {
      margin-top: 32px;
    }
  }

  img {
    margin-bottom: 20px;
  }
}

.cta__readmore {
  color: $accent;
  text-decoration: underline;
  text-decoration-skip-ink: auto;
  text-underline-offset: 0.2rem;
  @include fs(16);
  margin-top: 10px;
}

.cta--teaser {
  .cta__image {
    padding-bottom: 49.3857%;
  }

  .cta__label {
    background-color: rgba(255, 255, 255, 0.6);
  }

  .cta__visual {
    border-radius: 10px;
  }

  @media #{$medium} {
    .cta__content {
      min-height: 316px;
    }
  }

  @media #{$huge} {
    .cta__content {
      min-height: 330px;
    }
  }

  @media #{$massive} {
    .cta__content {
      min-height: 230px;
    }
  }
}

.cta__features {
  @include fs(12);
  list-style: none;
  margin: 10px 0 0;
  padding: 0;
  font-weight: 600;

  li {
    display: inline-block;
    margin: 0 15px 5px 0;
  }

  .icon {
    margin-right: 6px;
  }

  @media #{$massive} {
    @include fs(13);

    li {
      margin-right: 25px;
    }

    .icon {
      margin-right: 8px;
    }
  }
}

// cta property
.cta--property {
  .icon-bed {
    width: 16px;
    height: 15px;
  }

  .icon-bath {
    width: 21px;
    height: 15px;
  }

  .icon-surface {
    width: 14px;
    height: 14px;
  }

  .icon-house-outline {
    width: 14px;
    height: 14px;
  }
}

// cta project
.cta--project {
  background-color: #fff;

  .grid {
    margin-bottom: 0;
  }

  .cta__image {
    padding-bottom: 80%;
  }

  .cta__label {
    color: $accent;
  }

  .cta__title,
  .cta__subtitle {
    @include fs(21);
  }

  .cta__title {
    strong {
      color: $accent;
    }

    &:after {
      content: "";
      display: block;
      border-bottom: 6px solid $accent;
      width: 54px;
      margin: 14px 0 20px;
    }
  }

  .cta__body {
    @include fs(16);
    height: 60px;
    overflow: hidden;
    position: relative;

    &:after {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 40px;
      background: linear-gradient(rgba(255, 255, 255, 0), #fff);
    }
  }

  @media #{$medium} {
    margin-bottom: 60px;
    border-radius: $borderRadius;
    overflow: hidden;

    > .grid {
      display: flex;
    }

    .cta__visual {
      border-top-right-radius: 0;
      border-bottom-left-radius: $borderRadius;
      height: 100%;
    }

    .cta__image {
      min-height: 100%;
    }

    .cta__visual-footer {
      bottom: inherit;
      left: inherit;
      right: 10px;
      top: 25px;
    }
  }

  @media #{$larger} {
    .cta__content {
      padding: 20px 25px 10px;
    }
  }

  @media #{$huge} {
    .cta__content {
      padding: 40px 50px 10px;
    }

    .cta__body {
      height: 105px;
    }
  }

  @media #{$massive} {
    .cta__body {
      height: auto;
      @include fs(18);
      margin-bottom: 1em;

      &:after {
        display: none;
      }
    }

    .cta__content {
      padding: 45px 55px 10px;
    }

    .cta__visual-footer {
      top: 45px;
      right: 30px;
    }

    .cta__label {
      @include fs(12);
      padding: 6px 17px;
    }

    .cta__title {
      margin-bottom: 1.4em;
    }
  }
}

p.cta__properties {
  @include fs(13);
  text-transform: uppercase;
  color: #666;

  @media #{$huge} {
    float: right;
    margin-top: 28px;
    padding-left: 10px;
  }
}

// cta visual
.cta-visual {
  background-color: $accent;
  margin-bottom: 20px;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  transition: $transitionDefault;

  &:before {
    content: "";
    display: block;
    position: absolute;
    bottom: -50%;
    right: -25%;
    width: 1080px;
    height: 1000px;
    background-color: $grayLight;
    border-radius: 100%;
    transition: $transitionDefault;
  }

  @media #{$medium} {
    height: 260px;
    display: flex;
    margin-bottom: 0;
    background-color: $accent;

    &:before {
      bottom: -50%;
      right: -10%;
    }

    &:hover {
      background-color: $extraOrange;

      &:before {
        bottom: -45%;
        right: -8%;
        width: 1080px;
        height: 1000px;
      }
    }
  }
}

.cta-visual__image {
  display: block;
  position: absolute;
  right: 20px;
  bottom: 20px;
  filter: saturate(0.5);

  @media #{$maxMedium} {
    width: 60px;
  }

  @media #{$larger} {
    height: 260px;
    width: 38%;
    float: left;
    right: auto;
    bottom: auto;
    background-color: transparent;
    position: relative;
    display: flex;
    justify-content: center;
    align-content: center;
    padding-right: 9%;
    filter: saturate(1);
  }
}

.cta-visual__content {
  position: relative;
  padding: 20px;
  z-index: 1;

  p {
    max-width: 36ch;

    @media #{$medium} {
      max-width: 50ch;
    }
  }

  @media #{$medium} {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  @media #{$larger} {
    width: 80%;
    float: left;
    padding: 40px 60px;
    display: flex;
    flex-direction: column;
    height: 100%;
    max-width: 480px;
  }
}

.cta-visual__title {
  font-weight: 700;
}

.cta-visual__header {
  margin-bottom: 0;

  @media #{$medium} {
    margin-top: auto;
  }
}

.cta-visual__footer {
  margin-bottom: 0;
  padding-left: 20px;

  @media #{$larger} {
    padding-left: 0;
  }
}

.cta-visual__target {
  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 5;
  }
}

.cta-visual--full-width {
  .cta-visual__content {
    max-width: none;

    @media #{$medium} {
      position: static;
      background-color: $grayLight;
    }
  }

  &::before {
    @media #{$medium} {
      bottom: -130px;
      right: -70px;
    }
  }

  &:hover {
    &::before {
      @media #{$medium} {
        bottom: -115px;
        right: -40px;
      }
    }
  }
}

.cta-visual__body {
  margin-bottom: 5px;

  @media #{$large} {
    max-width: 44ch;
  }
}

.cta-visual__hero {
  @media #{$medium} {
    .hero__content:before {
      width: 790px;
      height: 790px;
    }
  }

  @media #{$larger} {
    .hero__content:before {
      width: 590px;
      height: 590px;
    }
  }
}

// reference
.references-grid {
  @media #{$medium} {
    .grid__item {
      &:nth-child(2n + 3) {
        clear: left;
      }
    }
  }

  @media #{$large} {
    .grid__item {
      &:nth-child(2n + 3) {
        clear: none;
      }

      &:nth-child(3n + 4) {
        clear: left;
      }
    }
  }
}

.cta__overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba($accent, 0.9);
  color: #fff;
  @include fs(19);
  text-align: center;
  padding: clamp(20px, 15%, 45px) 20px 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  justify-content: center;
  line-height: 1.2em;
  border-radius: 10px;
  transition: $transitionDefault;

  &:hover {
    background-color: rgba($extraOrange, 0.9);
  }

  @media #{$huge} {
    padding: clamp(20px, 15%, 75px) 20px 25px;
  }
}

.cta--quote {
  .cta__overlay {
    &:before,
    &:after {
      position: absolute;
      content: "";
      display: block;
      width: 43px;
      height: 30px;
    }

    &:before {
      top: 10px;
      left: 10px;
      background: url("../images/quote-top.svg") top left no-repeat;
    }

    &:after {
      bottom: 10px;
      right: 10px;
      background: url("../images/quote-bottom.svg") top left no-repeat;
    }
  }

  @media #{$medium} {
    .cta__overlay {
      &:before {
        top: 20px;
        left: 20px;
      }

      &:after {
        bottom: 20px;
        right: 20px;
      }
    }
  }
}

.cta__overlay-text {
  margin-bottom: 10px;
  font-weight: 500;
}

.cta__overlay-text--large {
  @include fs(19);
  line-height: 1.2em;

  @media #{$small} {
    @include fs(26);
  }

  @media #{$medium} {
    @include fluid-type(font-size, $mediumSize, $hugeSize, 26px, 41px);
  }
}

.cta__bottom-link {
  display: block;
  border-bottom: 1px solid $white;
  transition: border-color $transitionDefault;
  margin: clamp(8px, 4%, 30px) 0 0;
  @include fs(16);

  .cta:hover &,
  .cta:focus & {
    border-bottom-color: transparent;
  }

  @media #{$medium} {
    margin: auto 0 0;
  }
}

// reference detail
.reference-detail {
  img {
    margin-bottom: 25px;
  }

  &:before,
  &:after {
    position: absolute;
    content: "";
    display: block;
    width: 70px;
    height: 50px;
    background-repeat: no-repeat;
    background-position: top left;
    background-size: contain;
  }

  &:before {
    top: 10px;
    left: 10px;
    background-image: url("../images/quote-top.svg");
  }

  &:after {
    bottom: 10px;
    right: 10px;
    background-image: url("../images/quote-bottom.svg");
  }

  @media #{$medium} {
    &:before {
      top: 35px;
      left: 35px;
    }

    &:after {
      bottom: 35px;
      right: 35px;
    }
  }
}

.reference-detail__title {
  @include fluid-type(font-size, $mediumSize, $largerSize, 28px, 41px);
  margin-bottom: 10px;
}

.reference-detail__body {
  margin-bottom: 20px;

  @media #{$medium} {
    margin-bottom: 40px;
  }
}

.reference-detail__cite {
  margin-bottom: 0;

  &:before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    width: 50px;
    border-top: 1px solid #fff;
    margin-right: 10px;
  }
}

// video button
.video-btn {
  display: block;
  width: 100%;
  height: 100%;

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 5;
    background-color: rgba(0, 0, 0, 0.1);
    transition: $transitionDefault;
  }

  &:hover,
  &:focus {
    &:before {
      opacity: 0.7;
    }

    .video-btn__overlay {
      &:before,
      &:after {
        transform: scale(0.88);
      }
    }
  }
}

.video-btn__overlay {
  &:before,
  &:after {
    content: "";
    display: block;
    z-index: 10;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: scale(0.9);
    transition: $transitionDefault;
  }

  &:before {
    width: 90px;
    height: 90px;
    margin-left: -45px;
    margin-top: -45px;
    border-radius: 100%;
    border: 4px solid rgba(255, 255, 255, 0.8);
  }

  &:after {
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    border-color: transparent;
    border-left-color: #fff;
    border-width: 18px;
    border-left-width: 30px;
    margin-top: -16px;
    margin-left: -13px;
  }
}

// jumbotron
.jumbotron {
  @include clearfix;
  // background-color: #f7f7f7;

  @media #{$large} {
    .section-header {
      margin-bottom: 85px;
    }
  }
}

.jumbotron--c-outside {
  @media #{$medium} {
    .jumbotron__content {
      padding-top: 0;
    }
  }
}

.jumbotron--visual-bg {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  img.vh {
    visibility: hidden;
  }

  strong {
    font-weight: 800;
    @include optimize-font;
  }

  a {
    color: #fff;
  }

  p {
    margin-bottom: 15px;
  }

  @media #{$medium} {
    &:not(.jumbotron--has-box) .jumbotron__content {
      display: flex;
      min-height: 440px;
      justify-content: center;
      flex-direction: column;

      .grid {
        margin-bottom: 60px;
        width: 100%;
      }
    }
  }
}

.jumbotron--bg-overlay {
  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba($accent, 0.4);
    border-radius: $borderRadiusLarge;
  }
}

.jumbotron--office {
  @extend .jumbotron--bg-overlay;
  margin-bottom: 40px;

  .scroll-to-link {
    color: $white;
  }

  h2,
  h3 {
    color: $accent;
  }

  .jumbotron__content {
    .box {
      a {
        text-decoration: none;
        color: $accent;
      }

      .icon-box {
        margin-right: 10px;
      }
    }
  }

  @media #{$large} {
    margin-bottom: 125px;

    .box {
      margin-bottom: -125px;
    }
  }
}

.jumbotron--large-text {
  @media #{$medium} {
    p {
      @include fs(18);
    }
  }
}

.jumbotron--sell {
  h1 {
    @include fs(18);
    margin-bottom: 10px;
    font-weight: 800;

    @media #{$medium} {
      @include fs(25);
    }
  }

  a:not(.video-slideshow__link) {
    position: relative;
    text-decoration: none;

    &:after {
      position: absolute;
      left: 0;
      bottom: 0em;
      content: "";
      height: 1px;
      width: 100%;
      background-color: $accent;
      display: block;
    }
  }
}

.jumbotron--sell-index {
  .jumbotron__content {
    padding-bottom: 0;
  }

  @media #{$medium} {
    .jumbotron__content {
      width: 100%;
      max-width: inherit;
      padding-top: 40px;
    }
  }

  @media #{$large} {
    .medium--one-half:first-child {
      padding-top: 20px;
    }
  }

  @media #{$larger} {
    .medium--one-half:first-child {
      padding-top: 40px;
    }
  }

  @media #{$massive} {
    .medium--one-half:first-child {
      padding-left: 150px;
      padding-top: 160px;
    }
  }
}

.jumbotron--contact {
  h2 {
    color: rgba($accent, 0.75);
  }
}

.jumbotron--container-on-top {
  margin-bottom: 30px;

  @media #{$medium} {
    margin-bottom: 0;

    + .container .cta-form__body {
      margin-top: -240px;
    }
  }
}

.jumbotron__content {
  @extend .container;
  padding-top: 20px;
  padding-bottom: 20px;

  .section-header {
    padding-left: 0;
  }

  .cta__content {
    padding-left: 0;
    padding-right: 0;
  }
}

.jumbotron__content--about {
  text-align: center;
  padding: 50px 20px;
  max-width: 850px;
  margin-left: auto;
  margin-right: auto;

  h1,
  h2 {
    color: #fff;
  }

  .section-header {
    margin-bottom: 50px;
  }

  .logo {
    margin-bottom: 40px;
  }

  p {
    color: $white;
    margin-bottom: 1em;
  }

  @media #{$medium} {
    padding: 160px 20px;

    .logo {
      margin-bottom: 80px;
    }
  }
}

.jumbotron__footer {
  margin-top: 20px;
  text-align: center;

  .inline-link-list {
    margin-left: 0;

    li {
      margin-left: 5px;
      margin-right: 5px;

      &:first-child {
        margin-left: 0;
      }

      &::last-child {
        margin-right: 0;
      }
    }
  }

  @media #{$medium} {
    margin-top: 60px;

    .inline-link-list {
      li {
        margin-left: 12px;
        margin-right: 12px;
      }
    }
  }
}

.jumbotron__footer-title {
  @include fs(18);
  color: $black;
  font-weight: 600;
  margin-bottom: 1em;
}

// accordion
.accordion {
  @include clearfix;
  border-bottom: 1px solid $grayLight;
  margin-bottom: 17px;
}

.accordion--initial-open {
  .accordion__content {
    display: block;
  }
}

.accordion__title {
  @include fs(17);
  margin-bottom: 1em;
  cursor: pointer;

  &:after {
    display: block;
    content: "";
    height: 5px;
    width: 9px;
    background: url("../images/caret-accent.svg") center center no-repeat;
    background-size: contain;
    transition: $transitionDefault;
    opacity: 0.5;
    margin: 8px 10px 0 0;
    float: right;
  }

  &:hover,
  &:focus {
    &:after {
      transform: translateY(5px);
    }
  }

  &.active {
    &:after {
      transform: rotate(180deg);
    }
  }
}

.accordion__content {
  display: none;
  @include fs(14);
  padding-bottom: 5px;
}

.js-accordion-content {
  display: none;
}

// autocomplete search
.search-bar-container {
  position: relative;
  max-width: 650px;
  z-index: 900;
  margin-bottom: 30px;

  // &:before {
  //   content: ""; display: block;
  //   position: fixed; z-index: -1;
  //   top: 0; left: 50%; height: 100vh; width: 100vw;
  //   margin-left: -50vw;
  //   background-color: rgba(0, 0, 0, 0.2);
  //   pointer-events: none;
  //   opacity: 0;
  //   transition: $transitionDefault;
  // }

  &:hover {
    // &:before {
    //   opacity: 1;
    // }

    .filter-field {
      background-color: #fff;
    }
  }
}

.search-bar {
  position: relative;
  z-index: 500;
  width: 100%;

  button:not(.search-bar__remove-btn):not(.search-bar__remove-label-btn) {
    height: 50px;
    width: auto;
    min-width: 0;
    border: 0;
    margin-top: 0;
    text-align: center;
    position: relative;

    &:hover,
    &:focus {
      color: #666;
      background: 0;
    }
  }

  .filter-field {
    padding: 0 20px 0 70px;
    border: 0;
    border-radius: $borderRadius;
    background: #fafafa;
    height: 60px;
    @include fs(18);

    &::placeholder {
      color: rgba($accent, 0.5);
    }

    &:focus {
      background-color: #fff;
    }

    @media #{$medium} {
    }
  }

  &:before {
    content: "";
    display: block;
    position: absolute;
    left: 25px;
    top: 20px;
    width: 20px;
    height: 20px;
    background-image: url("../images/svg/search.svg");
    background-size: contain;
    background-repeat: no-repeat;
  }

  .custom-radio {
    margin-bottom: 0;
  }

  &.search-label-set {
    input {
      cursor: default;
      pointer-events: none;
    }
  }

  @media #{$medium} {
    button:not(.search-bar__remove-btn):not(.search-bar__remove-label-btn) {
      height: 100%;
      width: 75px;
    }
  }
}

.search-bar-info {
  text-align: center;
  margin: 15px 0 0;
  @include fs(16);

  a {
    color: #fff;
    text-decoration: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.8);

    &:hover,
    &:focus {
      border-bottom-color: #fff;
    }
  }
}

.search-bar__remove-label-btn {
  background: 0;
  padding: 0;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 10px;
  left: 40px;
  border: 1px solid #aaa;
  border-radius: 100%;
  min-width: inherit;
  line-height: 16px;
  display: none;
  text-align: center;
  z-index: 1000;
  box-shadow: none;

  .icon-close {
    margin: 0;
    width: 8px;
    height: 8px;
    fill: #aaa;
  }

  &:hover,
  &:focus {
    background-color: #fafafa;
    border-color: $accent;
    transform: none;

    .icon-close {
      fill: $accent;
    }
  }

  &.show {
    display: block;
  }

  @media #{$medium} {
  }
}

.search-bar-autocomplete {
  position: absolute;
  top: 57px;
  left: 0;
  right: 0;
  transition: 0.4s, cubic-bezier(0.645, 0.045, 0.355, 1);
  opacity: 0;
  transform: translateY(-20px);
  pointer-events: none;

  &:before,
  &:after {
    content: "";
    display: block;
  }

  &:after {
    position: absolute;
    left: 0;
    right: 0;
    top: -57px;
    bottom: 0;
    background-color: #fff;
    z-index: -1;
    border-radius: $borderRadius;
    transition: 0.4s, cubic-bezier(0.645, 0.045, 0.355, 1);
    box-shadow: 0 20px 50px rgba(50, 62, 77, 0.2);
    opacity: 0;
  }

  &.show {
    opacity: 1;
    transform: translateY(0);
    pointer-events: inherit;

    &:after {
      opacity: 1;
    }
  }

  @media #{$medium} {
  }
}

.search-bar-autocomplete__content {
  background-color: #fff;
  max-height: 275px;
  overflow-y: scroll;
  border-bottom-left-radius: $borderRadius;
  border-bottom-right-radius: $borderRadius;
}

.search-bar-length-item {
  @include fs(16);
  padding: 0;
  margin: 0;
  position: absolute;
  top: -1000px;
  left: -1000px;
  z-index: -5;
  width: auto;
  visibility: hidden;

  @media #{$medium} {
    @include fs(14);
  }
}

.search-bar-results {
  a {
    text-decoration: none;
  }

  .info {
    margin: 15px 17px 0;
    @include fs(14);
  }

  @media #{$medium} {
    .info {
      margin: 15px 30px 0;
    }
  }
}

.search-bar-results__title {
  @extend h6;
  color: $grayDark;
  padding: 15px 15px 10px;
  border-bottom: 1px solid $grayLight;
  text-transform: uppercase;
  @include fs(12);

  a {
    color: $gray;

    &:hover,
    &:focus {
      color: $accent;
    }
  }

  @media #{$medium} {
    padding: 25px 30px 15px;
    margin-bottom: 10px;
  }
}

.search-bar-results__thumbs {
  @extend .unstyled;
  @include clearfix;
  margin: 0 30px -10px;
  @include fs(12);

  li {
    float: left;
    margin: 0 20px 20px 0;
  }

  a {
    display: block;
    text-decoration: none;
    overflow: hidden;
    border-radius: 100%;

    &:hover,
    &:focus {
      opacity: 0.8;
    }
  }

  img {
    position: relative;
    display: block;
    width: 70px;
    height: 70px;

    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: url("../images/person-placeholder.jpg") center center
        no-repeat;
      background-size: cover;
    }
  }

  @media #{$medium} {
    height: 90px;
    overflow: hidden;
  }
}

@media (min-width: 767px) and (min-height: 900px) {
  .search-bar-results__thumbs {
    height: auto;
  }
}

.search-bar-results__buttons {
  @extend .unstyled;
  @include clearfix;
  margin: 15px 0 0;

  li {
    float: left;
    margin-left: 30px;
    margin-right: 50px;
  }

  a {
    color: $grayDark;

    &:hover,
    &:focus {
      color: $accent;
    }
  }

  @media #{$medium} {
    @include fs(22);
  }
}

.search-results-listing,
.search-results-properties {
  @extend .unstyled;
  @include clearfix;
  text-align: left;
  margin: 0 0 0.5em;

  p {
    margin-bottom: 0;
  }

  li {
    @include clearfix;
    padding: 7px 0;

    + li {
      padding-top: 7px;
    }
  }
}

.search-results-listing-item__title {
  display: block;
  color: $accent;
  margin-left: 15px;

  @media #{$medium} {
    margin-left: 30px;
    float: left;
  }
}

.search-results-listing-item__title--inactive {
  color: #ccc;
}

.search-results-listing-item__buttons {
  @include clearfix;
  padding-bottom: 5px;

  & > span {
    color: $gray;
  }

  @media #{$medium} {
    float: right;
    padding-bottom: 0;
    padding-right: 5px;
  }
}

.search-results-listing-item__button {
  display: block;
  float: left;
  margin: 10px 15px;
  @include fs(12);
  font-weight: 500;
  line-height: 1.2em;
  text-transform: uppercase;

  padding: 0;
  min-width: 95px;
  text-align: right;
  font-weight: 600;
  letter-spacing: 1px;
}

a.search-results-listing-item__button {
  color: $accent;

  &:hover {
    color: $accentHover;
  }
}

.search-results-properties {
  @include fs(16);

  li {
    position: relative;
    min-height: 70px;
    padding: 10px 10px 10px 85px;
  }

  &.profiles {
    .search-results-properties__thumb {
      background-image: url("../images/person-placeholder.jpg");
      transition: $transitionDefault;
      backface-visibility: hidden;
      filter: grayscale(100%);
    }

    a {
      display: block;
      text-decoration: none;

      &:hover,
      &:focus {
        .search-results-properties__thumb {
          filter: grayscale(0%);
        }
      }
    }
  }

  @media #{$medium} {
    li {
      padding: 8px 40px 8px 120px;
      min-height: 86px;
    }

    &.grid {
      margin-bottom: 10px;

      li {
        width: 33.33%;
        float: left;
        border-top: 0;
      }
    }
  }
}

.search-results-properties__thumb {
  position: absolute;
  display: block;
  top: 8px;
  left: 15px;
  width: 50px;
  height: 50px;
  background: #efefef center center no-repeat;
  background-size: cover;

  @media #{$medium} {
    left: 30px;
    width: 70px;
    height: 70px;
  }
}

.search-results-properties__content {
  min-height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media #{$medium} {
    min-height: 70px;
  }
}

.search-results-properties__label {
  display: block;
  margin-bottom: 0.6em;
  line-height: 1.3em;
  @include fs(14);

  @media #{$medium} {
    @include fs(16);
  }
}

.search-results-properties__ref {
  display: block;
  @include fs(12);
}

/* inline autocomplete */
.search-bar-container--inline {
  .search-bar {
    &:before {
      width: 14px;
      height: 14px;
      left: 15px;
      top: 23px;
    }

    .filter-field {
      background-color: rgba(203, 203, 203, 0.2);
      @include fs(16);
      padding-left: 40px;
      padding-right: 0;
      transition: background-color 0.3s $easeInOut;

      &:focus {
        background-color: #fff;
      }
    }
  }

  &:hover {
    .search-bar .filter-field {
      background-color: #fff;
    }
  }

  @media #{$large} {
    .search-bar {
      .filter-field {
        &:focus {
          width: 640px;
        }
      }
    }

    .search-bar-autocomplete {
      &.show {
        width: 640px;
      }
    }
  }
}

/* =============================================================================
   HEADER
   ========================================================================== */
.page-header {
  padding: 25px 0 0;
  @include clearfix;
  position: fixed;
  z-index: 999;
  background-color: white;
  width: 100%;
  height: 90px;
  transition: $headerTransition;
  top: 0;
  @include fs(17);

  @media #{$nav} {
    height: 115px;
    padding: 0;

    .container {
      display: flex;
      align-items: flex-end;
      height: 100%;
    }
  }
}

.page-header__actions {
  display: none;

  ul {
    list-style: none;
    margin: 0;
  }

  li {
    float: left;

    + li {
      margin-left: 20px;
    }
  }

  @media #{$nav} {
    display: block;
    position: absolute;
    top: 20px;
    right: 20px;
  }

  @media #{$larger} {
    right: 40px;
  }
}

.main-nav {
  display: none;
  @include optimize-font;
  color: $accent;
  width: 100%;

  a {
    text-decoration: none;
    color: $black;
    position: relative;
    display: block;
    text-transform: lowercase;

    &:before {
      content: "";
      display: block;
      position: absolute;
      width: 30px;
      height: 30px;
      top: 50%;
      left: -10px;
      margin-top: -16px;
      background-color: $accent;
      opacity: 0;
      transition: $transitionDefault;
      border-radius: 100%;
      z-index: -1;
      transform: scale(0.5);

      @media #{$larger} {
        width: 38px;
        height: 38px;
        left: -19px;
        margin-top: -18px;
      }
    }

    @media #{$nav} {
      margin-top: 10px;
    }
  }

  .main-nav-list {
    display: flex;
    justify-content: flex-end;
    padding: 20px 0;
    margin: 0;
    width: 100%;

    & .medium--right {
      margin-left: auto;
    }
  }

  a:hover,
  a:focus,
  li.active a {
    &:before {
      opacity: 1;
      transform: scale(1);
    }
  }

  li {
    margin-left: 15px;

    &:first-child {
      margin-left: 0;
    }

    // @media #{$nav} {
    //   margin-left: 30px;
    // }

    @media #{$larger} {
      margin-left: 30px;
    }

    @media #{$huge} {
      margin-left: 50px;
    }
  }

  @media #{$nav} {
    display: flex;
    margin-bottom: 10px;
  }
}

.page-header__logo {
  position: absolute;
  overflow: hidden;
  text-decoration: none;
  @include opacity(100);
  left: 50%;
  margin: 0 0 0 -70px;
  width: 140px;
  height: 61px;
  transition: $headerTransition;

  img {
    transition: $headerTransition;
    display: block;
  }

  &:hover,
  &:focus {
    @include opacity(80);
  }

  @media #{$nav} {
    height: 70px;
    width: 180px;
    left: inherit;
    margin: 6px 0 0;
    position: static;
  }
}

.drawer {
  display: none;
}

.drawer-background {
  position: absolute;
  left: -100%;
  top: 0;
  z-index: 9999;
  width: 100vw;
  height: 100vw;
}

.drawer__nav {
  float: right;
  z-index: 9999;
  width: 100%;
  flex: 1;

  ul {
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
  }

  @media #{$maxMedium} {
    padding-top: $gutter * 1.5;
  }

  @media #{$medium} {
    @include fs(18);
  }

  @media #{$larger} {
    @include fs(24);
  }
}

.drawer__nav-item-login-btn {
  color: $accent;
  font-weight: 600;
  margin: 0;
  margin-right: 3rem;
}

.drawer__nav-link {
  text-decoration: none;
  display: inline-block;
  margin-left: 30px;
  margin-top: 22px;
  @include fs(18);
  color: #fff;

  &:hover,
  &:focus {
    color: rgba(255, 255, 255, 0.8);
  }
}

.drawer__nav-item-link {
  position: relative;
  display: inline-block;
  color: white;
  text-decoration: none;
  font-weight: 400;

  &:hover,
  &:active,
  &:focus {
    color: rgba(255, 255, 255, 0.8);
  }
}

.drawer__nav-item-link--active {
  font-weight: bold;

  &:after {
    content: "";
    display: block;
    border-bottom: 2px solid $accent;
    position: absolute;
    bottom: -10px;
    left: 0;
    right: 0;
  }
}

.drawer__nav-item {
  margin: 10px 0;
  font-weight: 400;

  @media #{$medium} {
    margin: 15px 0;
  }
}

.drawer__nav-item-login {
  margin-bottom: 20px;

  @media #{$huge} {
    margin-bottom: 50px;
  }
}

.drawer__nav-item--divider {
  border-bottom: 0.1rem solid rgba(255, 255, 255, 0.3);
  padding-bottom: 30px;

  @media #{$medium} {
    padding-bottom: 40px;
    margin-bottom: 25px;
  }

  @media #{$huge} {
    padding-bottom: 50px;
    margin-bottom: 35px;
  }
}

.drawer__nav-item-accent a {
  font-weight: 600;
}

.notification a::after {
  content: "";
  display: flex;
  width: 0.5rem;
  height: 0.5rem;
  background-color: $accent;
  border-radius: 2rem;
}

.drawer__footer-contact-info {
  margin-top: 10px;
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;

  a {
    position: relative;
    text-decoration: none;
    color: white;
    margin: 0 10px 10px 20px;
    display: inline-block;
    padding: 0 0 5px;
    border-bottom: 1px solid #fff;

    span {
      position: absolute;
      margin-left: -20px;
      font-weight: bold;
    }

    + a {
      &:before {
        content: "-";
        display: inline-block;
        margin-left: -25px;
        margin-right: 25px;
      }
    }

    &::last-child {
      margin-right: 0;
    }

    &:hover,
    &:focus {
      opacity: 0.8;
    }
  }

  @media #{$medium} {
    margin-bottom: 30px;

    a {
      margin-right: 30px;

      + a {
        &:before {
          margin-left: -35px;
          margin-right: 35px;
        }
      }
    }
  }

  @media #{$huge} {
    @include fs(18);
  }
}

.drawer__footer {
  fill: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 2rem;
  width: 100%;
  flex-wrap: wrap;
  justify-self: flex-end;

  svg {
    fill: #fff;
  }
}

.drawer__footer-social {
  & li a svg {
    fill: white;
  }
}

.drawer__footer-contact-info-tel {
  margin-bottom: 1rem;
}

.modal-nav-trigger {
  cursor: pointer;
  position: absolute;
  right: 30px;
  top: 15px;
  width: 25px;
  height: 15px;

  @media #{$nav} {
    display: none;
  }
}

.main-nav__trigger {
  display: block;
  position: relative;
  padding: 12px 20px 12px 55px;
  background-color: transparent;
  color: $black;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: absolute;
  top: 15px;
  right: 20px;
  border: 1px solid #efefef;

  .icon-hamburger {
    position: absolute;
    left: 10px;
    top: 18px;
  }

  &:hover,
  &:focus {
    background-color: transparent;
    box-shadow: none;
  }

  &:active {
    transform: none;
  }
}

.main-nav__wrap {
  display: none;
  margin: 0 0 15px;

  @media #{$medium} {
    margin: 0 0 0 -20px;
    display: block;
  }
}

.skip-link {
  left: -999px;
  position: absolute;
  top: auto;
  overflow: hidden;
  z-index: -999;
  white-space: nowrap;

  &:focus {
    top: 0;
    left: 0;
    z-index: 999;
    background-color: #fff;
    padding: 0.5em 1em;
    @include fs(20);
  }
}

// markee
.markee {
  border: 1px solid $accent;
  border-radius: 50px;
  color: $accent;
  padding: 8px 15px;
  position: relative;
  margin-top: 40px;
  font-weight: 500;
  overflow: hidden;
  height: 42px;
  width: 300px;
  white-space: nowrap;

  @media #{$medium} {
    margin-top: 60px;
  }

  @media #{$larger} {
    width: 400px;
  }

  @media #{$huge} {
    width: 250px;
  }
}

.markee__text {
  display: inline-block;
  padding-left: 105%;
  /* show the marquee just outside the paragraph */
  animation: marquee 11s linear infinite;
}

@keyframes marquee {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}

/* =============================================================================
   MAIN
   ========================================================================== */

.main {
  @include clearfix;
  padding-top: 90px;
  padding-bottom: 20px;

  &:has(.project) {
    padding-bottom: 0;

    @media #{$nav} {
      padding-bottom: 0;
    }
  }

  @media #{$nav} {
    padding-top: 125px;
    padding-bottom: 80px;
  }
}

.main-overflow {
  overflow: hidden;
  margin-bottom: -20px;

  @media #{$medium} {
    margin-bottom: -80px;
  }
}

/* =============================================================================
   FOOTER
   ========================================================================== */

.page-footer {
  background-color: $grayLight;

  .grid {
    margin-bottom: 0;
  }
}

.page-footer--offices-overflow {
  @media #{$medium} {
    margin-top: 200px;

    .page-footer__content {
      padding-top: 150px;
    }

    .page-footer__offices {
      margin-top: -335px;
    }
  }
}

.page-footer__content {
  margin-bottom: 20px;
  padding-top: 30px;

  @media #{$medium} {
    padding-top: 70px;
  }
}

.page-footer__title {
  @extend h3;

  @media #{$maxMedium} {
    margin-top: 20px;
  }
}

.page-footer__title--small {
  @include fs(16);
}

.page-footer__bottom {
  padding: 30px 0 70px;
  margin-top: 30px;
  @include fs(13);
  position: relative;

  a {
    text-decoration: none;
    color: #000;

    &:hover,
    &:focus {
      color: $accent;
    }
  }

  .social {
    margin-bottom: 14px;
  }

  .social__item {
    display: inline-block;
    float: none;
  }

  &:after {
    content: "";
    width: 100%;
    height: 2px;
    position: absolute;
    top: 0;
    left: 0;
    background: radial-gradient(circle, #bbbbbb 7%, transparent 7%);
    background-size: 30px 30px;
    height: 20px;
  }

  @media #{$medium} {
    padding: 43px 0 0;

    .social {
      text-align: center;
    }
  }
}

.page-footer__logo {
  display: block;
  @include fs(25);
  font-weight: 600;
  text-transform: uppercase;
  margin: 0 10px 20px 0;

  &:hover,
  &:focus {
    opacity: 0.8;
  }
}

.page-actions {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 500;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.page-actions__button,
.page-actions__button-rect {
  position: relative;
  display: block;
  @include fs(16);
  font-weight: 500;
  text-decoration: none;
  color: #fff;
  line-height: 1.2em;
  float: left;

  &:hover,
  &:focus {
    color: #fff;
  }

  @media #{$medium} {
    @include fs(20);
  }
}

.page-actions__button {
  width: 180px;
  height: 65px;
  overflow: hidden;
  text-align: right;
  padding: 15px 20px 0 20px;
  z-index: 10;

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: -90px;
    left: 40px;
    background-color: $accent;
    border-radius: 100%;
    width: 360px;
    height: 360px;
    z-index: -1;
    transition: $transitionDefault;
  }

  &:hover,
  &:focus {
    &:before {
      transform: scale(1.05);
    }
  }

  @media #{$medium} {
    width: 200px;
    height: 95px;
    padding: 25px 20px 0 20px;
  }
}

.page-actions__button-rect {
  background-color: #fff;
  color: $accent;
  height: 65px;
  width: 300px;
  margin: 0 -170px 0 0;
  padding: 16px 0 0 25px;
  border-radius: 10px 10px 0px 0px;

  &:hover,
  &:focus {
    color: $accentHover;

    + .page-actions__button {
      &:before {
        transform: translateX(10px);
      }
    }
  }

  @media #{$medium} {
    width: 340px;
    height: 95px;
    padding: 25px 0 0 25px;
  }
}

a.esign {
  width: 80px;
  height: 20px;
  background-image: url("../images/logo-esign.svg");
  background-size: 77px 20px;
  background-repeat: no-repeat;
  background-position: top left;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
  text-indent: -9999px;
  opacity: 0.6;
  transition-duration: 0.5s;
  display: block;
  outline: 0;
  z-index: 9999;
  margin-top: 20px;

  &:hover,
  &:focus {
    opacity: 1;
  }

  @media #{$medium} {
    width: 16px;
    margin-top: 0;
    // float: left;
    display: inline-block;
    margin: 0;

    &:hover,
    &:focus {
      opacity: 1;
      width: 80px;
    }
  }
}

.page-ctas {
  display: none;

  .button {
    margin: 0 20px 0 0;
    clear: none;

    &:hover,
    &:focus {
      transform: translateY(-10px);
    }
  }

  @media #{$medium} {
    display: block;
    position: fixed;
    z-index: 20000;
    bottom: -3px;
    right: 0;
  }
}

.cc-window.cc-bottom {
  background-color: rgba(255, 255, 255, 0.9);
  font-family: $defaultFont;
  border-top: 1px solid #efefef;
  z-index: 40000;
  border-radius: 0 !important;

  .cc-btn {
    @extend .button;
    padding: 10px 25px;
    border: 0;
    margin: 0;
  }

  @media #{$medium} {
    right: 50% !important;
    width: auto !important;
    border-right: 1px solid #efefef;
  }
}

@media screen and (max-width: 414px) and (orientation: portrait),
  screen and (max-width: 736px) and (orientation: landscape) {
  .cc-window.cc-bottom {
    top: 90px;
    bottom: inherit !important;
  }
}

/* =============================================================================
   COMPONENTS
   ========================================================================== */

// Rte
.large {
  @include fs(20);
}

.center-block {
  @include clearfix;
  text-align: center;
  margin: 20px 0;

  .button {
    float: none;
    display: inline-block;
  }
}

// Article
section {
  position: relative;
}

article {
  @include clearfix;
  margin: 0 0 20px;

  a {
    img {
      display: block;
      @include opacity(100);
    }

    &:hover,
    &:focus {
      img {
        @include opacity(80);
      }
    }
  }
}

.article-detail__image {
  margin-bottom: 1.5em;
}

.cta--spotlight-blog {
  margin-bottom: 40px;

  @media #{$medium} {
    margin-bottom: 80px;
  }

  .cta__title {
    @include lh(30);
  }

  .cta__body {
    height: 60px;

    @media #{$medium} {
      @include fs(18);
      @include lh(34);
    }

    @media #{$large} {
      height: 150px;
    }

    @media #{$larger} {
      height: 200px;
    }

    @media #{$massive} {
      height: auto;
    }
  }
  .cta__target {
    color: $accent;
  }

  .cta__label--article-filter {
    padding: 15px;
    @include fs(12);
  }
}

.overview-blog-articles {
  @media #{$medium} {
    .medium--one-half {
      &:nth-child(2n + 3) {
        clear: left;
      }
    }
  }

  @media #{$large} {
    .medium--one-half.large--one-third {
      &:nth-child(2n + 3) {
        clear: none;
      }

      &:nth-child(3n + 4) {
        clear: left;
      }
    }
  }
}

.cta--blog {
  margin-bottom: 40px;

  &:hover,
  &:focus,
  &:active {
    .cta__target {
      color: $accentHover;
    }

    .cta__image {
      transform: scale(1.1);
    }
  }

  .cta__visual {
    overflow: hidden;
    position: relative;
  }

  .cta__image {
    padding-bottom: 66.59%;
  }

  .cta__image {
    transition: $transitionDefault;
  }

  time {
    margin-top: 20px;
    text-transform: lowercase;
    color: #7e7e7e;
  }

  .cta__content {
    min-height: 0;
    padding-right: 0;
    padding-top: 5px;
  }

  .cta__target {
    color: $accent;
    transition: $transitionDefault;
  }

  .cta__title {
    margin-bottom: 15px;
    @include fluid-type(font-size, $mediumSize, $largerSize, 18px, 20px);
  }

  .cta__label--article-filter {
    padding: 10px;
    @include fs(10);
  }
}

/*
.home-article-ctas {
  .cta--blog {
    margin-bottom: 0;
  }
}
*/

.cta__label--article-filter {
  padding: 15px;
  @include display-font;
  font-weight: 600;
  @include fs(12);
  letter-spacing: 0.15rem;
  background-color: rgba($white, 0.75);
}

/* =================================================
   Blog filters
   ================================================= */

.blog-filters-wrap__title {
  margin-bottom: 15px;

  @media #{$medium} {
    display: inline-block;
    margin-bottom: 0;
  }
}

.blog-filters-wrap {
  width: 100%;
  @include fs(11);
  @include display-font;
  font-weight: 600;

  letter-spacing: 0.15rem;
  text-transform: uppercase;
  padding-top: 10px;
  margin-bottom: 40px;

  @media #{$medium} {
    display: flex;
    align-items: center;
  }
}

.blog-filters {
  margin-bottom: 0;
  list-style: none;
  display: inline-block;
  margin-left: 0;
  padding-left: 0;

  li {
    @include clearfix;
    margin-bottom: 15px;
    margin-left: 0;
  }

  @media #{$medium} {
    li {
      display: inline-block;
      margin-bottom: 0;
      margin-left: 15px;
    }
  }
}

.blog-filters__link {
  display: inline-block;
  margin-bottom: 0;
  background: $white;
  padding: 10px;
  color: $black;
  box-shadow: none;
  text-decoration: none;
  border-radius: $borderRadius;

  &:hover,
  &:focus {
    background-color: $accent;
    color: $white;
    transform: translateY(-5px);
  }
}

.blog-filters__link--active {
  background-color: $accent;
  color: $white;
}

/* =================================================
   Blog detail
   ================================================= */

.blog-article-header-wrap {
  width: 100%;
  height: 0;
  padding-bottom: 40.8163265%;
  overflow: hidden;
  position: relative;
  margin-bottom: 30px;
  border-radius: 10px;

  @media #{$medium} {
    margin-bottom: 60px;
  }
}
/*
.blog-article-header-wrap--no-video {
  height: 50px;
  margin-bottom: 20px;
  display: flex;
  align-items: flex-end;

  .container {
    width: 100%;
  }

  @media #{$medium} {
    height: 300px;
    margin-bottom: 60px;
  }
}*/

.blog-article-header {
  background-size: cover;
  background-position: center;

  width: 100%;
  padding-bottom: inherit;
  /*  position: absolute;
  top: -10px;
  left: -10px;
  right: -10px;
  bottom: -10px;*/

  /*  filter: url(blur.svg#blur);
  -webkit-filter: blur(10px);
  filter: blur(10px);*/

  img {
    width: 100%;
    /*object-fit: cover;
    object-position: center;*/
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    @include object-fit(cover, center);
    /*visibility: hidden;
    display: none;*/
  }
}

.blog-article-header-wrap__back {
  color: $accent;

  text-decoration: underline;
  display: block;
  font-weight: 800;
  margin-bottom: 20px;

  &:hover,
  &:focus,
  &:active {
    color: $accent;
  }

  @media #{$medium} {
    margin-bottom: 60px;
  }
}

.blog-article-visual-wrap {
  overflow: hidden;
  margin-bottom: 40px;
  // overflow: visible;
  margin-top: -100px;

  @media #{$medium} {
    margin-bottom: 80px;
    margin-top: -375px;
  }

  .video-container,
  img {
    margin-bottom: 0;
    border-radius: 10px;
  }
}

.blog-article__labels {
  //ul {
  list-style: none;
  display: inline-block;

  // }
}

.blog-article__info {
  text-transform: uppercase;
  letter-spacing: 0.15rem;
  @include fs(11);
  @include display-font;
  font-weight: 600;
}

.blog-article__intro {
  @include fs(18);
  @include lh(34);
  margin-bottom: 20px;

  @media #{$medium} {
    @include fs(22);
    @include lh(38);
  }
}

.blog-article__date {
  display: block;
  margin-bottom: 20px;
  text-transform: uppercase;
  color: #7e7e7e;
  @include fs(14);
  letter-spacing: 0;
  font-weight: 400;
}

.blog-article__label {
  display: inline-block;
  margin-right: 5px;

  a {
    border-radius: 5px;
    text-decoration: none;
    display: block;
    padding: 5px 10px;
    background-color: $white;
    transition: $transitionDefault;

    &:hover,
    &:focus,
    &:active {
      background-color: $accent;
      color: $white;
    }
  }
}

.blog-article__title {
  @include fluid-type(font-size, $mediumSize, $hugeSize, 24px, 40px);
  @include fluid-type(line-height, $mediumSize, $hugeSize, 31px, 47px);
  font-weight: 800;
  margin-bottom: 30px;
}

.blog-article__body {
  //@include display-font;
  margin-bottom: 60px;
  color: #323e4d;
  @include optimize-font;

  @media #{$medium} {
    margin-bottom: 150px;
    @include fs(20);
  }

  img {
    width: 100%;
    height: auto;
  }

  blockquote {
    @include fs(20);
    border-left: 1px solid $accent;
    padding: 20px;
    font-weight: 100;

    @media #{$medium} {
      @include fs(30);
      @include lh(50);
      padding-left: 75px;
    }
  }
}

// Note
.note {
  @include clearfix;
  margin: 0 0 20px;
  padding: 15px 20px 0;
  background: #efefef;
  border-radius: $borderRadius;
  @include fs(16);

  ul,
  p {
    margin-bottom: 1em;
    @include fs(16);
    line-height: 1.5em;
  }

  @media #{$medium} {
    margin-bottom: 40px;
  }
}

.note--success {
  color: $success;
  background-color: $successBg;

  h2,
  h3 {
    color: $success;
  }
}

.note--error {
  color: $error;
  background-color: $errorBg;
}

.articles-list {
  list-style: none;
  margin: 20px 0;
  @include fs(16);

  a {
    @include clearfix;
    color: $accent;
    text-decoration: none;
    line-height: 1.5em;

    &:hover,
    &:focus {
      color: $accentHover;
    }
  }

  li {
    margin-bottom: 20px;
  }

  img {
    display: block;
    width: 100px;
    float: left;
    margin-right: 15px;
  }

  @media #{$medium} {
    margin-top: 0;

    img {
      width: 160px;
      margin-right: 30px;
    }
  }
}

// Slideshow
.cycle-slideshow {
  width: 100%;
  position: relative;
}

.cycle-pager {
  width: 100%;
  text-align: center;

  span {
    display: inline-block;
    margin: 0 3px;
    font-size: 36px;
    height: 20px;
    width: 20px;
    line-height: 14px;
    cursor: pointer;
    border: 2px solid #fff;
    border-radius: 20px;
    color: #fff;
    overflow: hidden;
    text-indent: -500px;
    transition: $transitionDefault;

    &:hover,
    &:focus {
      transition: none;
    }
  }

  span:hover,
  span:focus,
  .cycle-pager-active {
    text-indent: 0;
  }
}

// Map
.map {
  width: 100%;
  height: 350px;
  background: #efefef;
  margin: 0 0 40px;

  @media #{$medium} {
    height: 550px;
  }
}

// Social
.social {
  @include clearfix;
  list-style: none;
  margin: 0 0 1em -25px;

  @media #{$medium} {
    margin-left: -35px;
  }
}

.social__item {
  padding: 0 0 5px 25px;
  float: left;

  @media #{$medium} {
    padding-left: 35px;
  }
}

.social__link {
  display: block;
  text-decoration: none;

  &:hover,
  &:focus {
    opacity: 0.8;
  }
}

// link list
.inline-link-list {
  list-style: none;
  margin: 0 0 0 -10px;

  li {
    display: inline-block;
    margin: 0 5px 10px;
  }

  a {
    @extend .button;
    @extend .button--black;
  }

  @media #{$medium} {
    margin-bottom: 1em;

    li {
      margin: 0 10px 10px;
    }
  }

  @media #{$large} {
    li {
      margin-right: 25px;
    }
  }
}

.column-link-list {
  list-style: none;
  margin: 0 0 1.5em;
  column-count: 2;
  column-gap: 10px;

  li {
    page-break-inside: avoid;
  }

  @media #{$medium} {
    column-count: 3;
    column-gap: 40px;
  }

  @media #{$larger} {
    column-count: 4;
  }
}

// section
.section {
  margin-bottom: 50px;
  position: relative;

  @media #{$medium} {
    margin-bottom: 110px;
  }
}

.section--dots {
  position: relative;
  padding-top: 50px;
  margin-top: -50px;

  @media #{$medium} {
    margin-top: -50px;
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: auto;
    height: 100%;
    overflow: hidden;
    z-index: -1;
    pointer-events: none;
    display: block;
    max-width: 1510px;
    background: radial-gradient(circle, #bbbbbb 7%, transparent 7%);
    background-size: 30px 30px;
    margin: 0 auto;

    @media #{$larger} {
      margin-left: auto;
      margin-right: auto;
      max-width: 1490px;
    }

    @media #{$huge} {
      margin-left: auto;
      margin-right: auto;
      max-width: 1470px;
    }
  }
}

.section--no-mb {
  margin-bottom: 0;
}

.section--last {
  margin-bottom: 0;
}

.section--on-top {
  z-index: 11;
  position: relative;
}

.section-header {
  position: relative;
  text-align: center;
  margin-bottom: 40px;

  @media #{$medium} {
    padding-left: 50px;
    padding-right: 50px;
    // margin-bottom: 60px;
  }
}

.section-header__title {
  margin-bottom: 20px;
  color: $black;
  @include fs(21);
  font-weight: 600;
}

.section-header__title--left {
  text-align: left;

  &:after {
    margin-left: 0;
    margin-right: 0;
  }
}

.section-header--dots {
  @extend .dots;
  display: flex;
  justify-content: center;

  .section-header__title {
    background-color: $white;
    margin-bottom: 0;
  }
}

.section-header__right {
  @media #{$medium} {
    position: absolute;
    top: 44px;
    right: 0;
  }
}

//section

.section--yellow-visual {
  overflow: hidden;
  position: relative;

  &:after {
    z-index: -1;
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    margin-right: -200px;

    transform: translateX(40%);
    background-image: url("../images/sell/yellow-visual.svg");
    width: 1000px;
    height: 400px;

    @media #{$larger} {
      width: 1300px;
      height: 600px;
      margin-bottom: -50px;
    }

    @media #{$huge} {
      transform: translateX(30%);
    }
  }
}

.section--mb0 {
  margin-bottom: 0;
}
.section--below {
  position: relative;
  z-index: 1;
}

.section--br {
  border-radius: 10px;
}

.section--padding {
  padding: 20px 0;

  @media #{$medium} {
    padding: 60px 0;
  }
  @media #{$huge} {
    padding: 90px 0;
  }
}

.section--gray-pulled {
  position: relative;
  &:before {
    background-color: $grayLight;
    //background-color: red;
    z-index: -1;
    content: "";
    position: absolute;
    top: 0;
    margin-top: -220px;
    left: 0;
    width: 100%;
    height: calc(100% + 200px);
    margin-bottom: 20px;
  }
}

.section--gray {
  background-color: $grayLight;
}

.section--light-blue {
  background-color: rgba($accent, 0.02);
}

.section--pt {
  padding-top: 50px;

  @media #{$medium} {
    padding-top: 90px;
  }

  @media #{$huge} {
    padding-top: 120px;
  }
}
.section--pt-large {
  padding-top: 100px;

  @media #{$medium} {
    padding-top: 180px;
  }

  @media #{$huge} {
    padding-top: 270px;
  }
}

.section--pull {
  position: relative;
  top: 0px;
  margin-top: -40px;

  @media #{$medium} {
    margin-top: -75px;
  }
  @media #{$huge} {
    margin-top: -125px;
  }
}

.section--medium {
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
}

.section--white {
  background-color: $white;
}

.section--project-hero,
.section--project-contact {
  padding: $gutter * 0.5 0;

  .project__heading {
    color: inherit;
  }

  .section__content {
    max-width: none;
    margin: 0;
    padding: $gutter * 0.6;
    border-radius: $borderRadiusMedium;
    text-align: center;
    font-size: 20px;
    font-weight: 300;

    strong {
      font-weight: 500;
      font-family: $bureauSerif;
    }

    @media #{$small} {
      padding: $gutter;
      font-size: clamp(22px, 4vw, 38px);
    }

    @media #{$medium} {
      padding: $gutter * 1.5;
    }

    @media #{$huge} {
      padding: $gutter * 3;
    }
  }

  .rte {
    max-width: 30ch;
    margin-inline: auto;
  }

  @media #{$medium} {
    padding: $gutter 0;
  }

  @media #{$huge} {
    padding: $gutter * 1.5 0;
  }
}

.section--project-contact {
  .section__content {
    text-align: left;
  }
}

.section--rounded {
  border-radius: $borderRadiusMedium;
}

.section--rounded-overlap {
  border-radius: $borderRadiusMedium $borderRadiusMedium 0 0;
  margin-top: -$borderRadiusMedium;
}

.section__content {
  max-width: 1290px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
  /*  width: 100%;*/
}

.section__content--small {
  max-width: 1140px;
}
.section__content--large {
  max-width: 1640px;
}

.section--large {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}

.section--medium {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.section--small {
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
}

.section--margin {
  /*margin-left: 40px;
  margin-right: 40px;*/

  @media #{$medium} {
    margin-left: 60px;
    margin-right: 60px;
  }

  @media #{$huge} {
    margin-left: 140px;
    margin-right: 140px;
  }
}

// landscape slideshow
.landscape-slideshow {
  position: relative;
  overflow: hidden;
  height: 270px;
  background-color: $grayLight;
  text-align: center;

  @media #{$medium} {
    height: 600px;
    margin-bottom: 60px;
  }
}

.landscape-slideshow--video {
  height: 240px;

  @media #{$medium} {
    height: 349px;
  }

  @media #{$large} {
    height: 538px;
    margin-bottom: 60px;
  }

  @media #{$larger} {
    height: 600px;
  }
}

.slick-prev,
.slick-next {
  position: absolute;
  top: 50%;
  margin-top: -18px;
  z-index: 600;
  width: 36px;
  height: 36px;
  border-radius: 100%;
  background-color: #fff;
  float: left;
  text-align: center;
  line-height: 10px;
  cursor: pointer;
  transition: $transitionDefault;
  padding: 0;

  .icon {
    width: 15px;
    position: relative;
    left: 7px;
    transition: $transitionDefault;
    fill: $accent;
    backface-visibility: hidden;
  }

  &:hover,
  &:focus {
    background-color: #fff;

    .icon {
      fill: $accentHover;
    }
  }
}

.slick-prev {
  left: 20px;
  &:hover,
  &:focus {
    transform: translateX(-2px);
  }

  @media #{$huge} {
    left: 40px;
  }
}

.slick-next {
  float: right;
  right: 20px;

  &:hover,
  &:focus {
    transform: translateX(2px);
  }

  @media #{$huge} {
    right: 40px;
  }
}

.slick-dots {
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  list-style: none;
  margin: 0;
  padding: $gutter / 2 0;
  width: 100%;

  li {
    margin: 0;
    width: 12px;
    height: 12px;
    border-radius: 100%;
    background-color: rgba($accent, 0.4);
    cursor: pointer;
    transition: $transitionDefault;

    &:hover,
    &:focus-within {
      background-color: $extraOrange;
    }
  }

  button {
    position: absolute;
    padding: 0;
    margin: 0;
    visibility: hidden;
  }

  .slick-active {
    background-color: $accent;
  }
}

.landscape-slideshow__slide {
  display: inline-block;
  background-color: #fafafa;
  transition: $transitionDefault;
}

.slick-slide {
  opacity: 0.25;
}

.slick-current {
  opacity: 1;
}

.landscape-slideshow__img {
  img {
    height: 270px;
    display: inline-block;
  }

  @media #{$medium} {
    img {
      height: 600px;
    }
  }
}

// box
.box {
  background-color: #fafafa;
  padding: 20px;

  .message-estimate-success {
    p {
      @include fs(16);
    }
  }

  input[type="text"],
  input[type="email"],
  input[type="tel"],
  textarea {
    background-color: #fafafa;
  }

  @media #{$medium} {
    padding: 50px 65px 30px;
  }
}

.box--black {
  background-color: $black;
  color: #fff;

  h2,
  h3 {
    color: #fff;
  }
}

.box--large {
  @media #{$medium} {
    @include fs(19);

    .box__header-title {
      @include fs(32);
    }
  }
}

.box__header {
}

.box__header-title {
  @include fs(18);
  font-weight: 600;

  @media #{$medium} {
    @include fs(20);
  }
}

.box__content {
}

// image links
.image-links-block {
  margin-bottom: 0;

  a {
    display: inline-block;
    text-decoration: none;
    margin: 0 20px 10px;
  }

  @media #{$medium} {
    a {
      margin: 10px 30px 10px;
    }
  }
}

// hero
.hero {
  position: relative;
  background: #fafafa;
  margin-bottom: 40px;
  width: 100%;
  overflow: hidden;
  border-radius: 10px;

  &:before {
    content: "";
    display: block;
    height: 250px;
    background: url("../images/masthead-home.jpg") center no-repeat;
    background-size: cover;
  }

  @media #{$large} {
    margin-bottom: 100px;

    &:before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      height: auto;
      left: 50%;
      margin-left: -430px;
    }
  }
}

.hero__content {
  padding: 20px 0 1px;

  .container {
    width: 100%;
  }

  @media #{$large} {
    height: 300px;
    overflow: hidden;
    display: flex;
    align-items: flex-end;
    position: relative;
    padding: 0;

    &:before {
      position: absolute;
      content: "";
      display: block;
      width: 590px;
      height: 590px;
      border-radius: 100%;
      background-color: #fafafa;
      top: -80px;
      left: 50%;
      margin-left: -750px;
    }
  }

  @media #{$large} {
    height: 340px;
  }
}

.hero__title {
  font-weight: 800;
  @include fs(30);
  margin-bottom: 0.8em;
  max-width: 410px;
  color: $accent;
  line-height: 1.2em;

  small {
    @include fs(20);
  }

  @media #{$large} {
    @include fs(48);

    small {
      @include fs(28);
    }
  }
}

.hero__body {

}

.hero--contact {
  &:before {
    background-image: url("../images/hero-contact.jpg");
  }
}

.hero--about {
  &:before {
    background-image: url("../images/hero-about.jpg");
  }

  @media #{$maxMedium} {
    &:before {
      background-position: top left -310px;
    }
  }
}

.hero--sales {
  &:before {
    background-image: url("../images/hero-sales-ai.jpg");
    background-position: top right;
  }

  @media #{$maxMedium} {
    &:before {
      background-image: url("../images/hero-sales.jpg");
      background-position: top left;
    }
  }
}

.hero--team {
  &:before {
    background-image: url("../images/hero-team-ai.jpg");
    background-position: bottom right;
  }

  @media #{$maxMedium} {
    &:before {
      background-image: url("../images/hero-team.jpg");
    }
  }
}

.hero--approach {
  &:before {
    background-image: url("../images/about-visual-1.jpg");
  }

  @media #{$maxMedium} {
    &:before {
      background-position: 67% center;
    }
  }
}

.hero--references {
  &:before {
    background-image: url("../images/hero-references.jpg");
  }
}

.hero--estimation-simulator {
  &:before {
    background-image: url("../images/hero-estimation-tool-ai.jpg");
    background-position: 50% 31%;
  }

  @media #{$maxMedium} {
    &:before {
      background-image: url("../images/hero-estimation-tool.jpg");
      background-position: top right -80px;
    }
  }
}

.hero--vacatures {
  &:before {
    background-image: url("../images/hero-vacatures-2.jpg");
  }

  @media #{$medium} {
    &:before {
      margin-left: -270px;
    }
  }
}

/* =============================================================================
   PAGES
   ========================================================================== */
body.property-show,
body.sell-page {
  background-color: #fff;
}

body.sell-page .title-large-icon {
  padding-top: 150px;

  @media #{$larger} {
    padding-top: 70px;
  }
}

.masthead-home {
  position: relative;
  background: $grayLight;
  border-radius: 10px;
  margin-bottom: 60px;

  p {
    position: relative;
  }

  @media #{$medium} {
    margin-bottom: 150px;

    .grid {
      width: 100%;
      margin-bottom: 0;
    }

    .more-link {
      margin-top: 80px;
      @include fs(18);
    }

    .jumbotron__content {
      height: 600px;
      overflow: hidden;
      display: inline-flex;
      align-items: flex-end;
      border-radius: 10px;
      overflow: hidden;
      padding: 0 20px 80px 100px;
      z-index: 1;

      &:before {
        position: absolute;
        content: "";
        display: block;
        width: 1200px;
        height: 1200px;
        border-radius: 100%;
        background-color: $grayLight;
        top: -170px;
        right: 10px;
        // transform: translateX(-100%);
      }

      &:after {
        position: absolute;
        content: "";
        display: block;
        width: 1400px;
        height: 1400px;
        border-radius: 100%;
        background-color: $extraOrange;
        top: -250px;
        right: 0;
        z-index: -1;
      }
    }
  }

  @media #{$large} {
    .jumbotron__content {
      &:before {
        transform: translateX(-110%);
      }
    }
  }
}

.masthead-home__title {
  position: relative;
  @include fluid-type(font-size, $mediumSize, $largerSize, 30px, 49px);
  font-family: $defaultFont;
  font-weight: 800;
  margin: 10px 0 5px;

  &:first-letter {
    text-transform: uppercase;
  }

  @media #{$maxMedium} {
    & + p + .more-link {
      padding-left: 20px;
      margin-bottom: 0;
    }
  }
}

.masthead-home__visual {
  margin-bottom: 0;
  background: url("../images/masthead-home.jpg") center no-repeat;
  background-size: cover;
  border-radius: 10px;
  overflow: hidden;
  width: 100%;

  @media #{$medium} {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    right: 0;
    height: auto;
    display: block;
  }
}

// properties counter
.properties-countdown {
  background-color: $accent;
  color: #fff;
  text-align: center;
  padding: 30px 20px 40px;
  border-radius: 10px;

  a {
    color: #fff;
  }

  @media #{$medium} {
    width: 310px;
    height: 310px;
    border-radius: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px 50px;
    position: absolute;
    bottom: -150px;
    left: 50px;
    z-index: 10;
  }

  @media (min-width: 1680px) {
    left: 80px;
  }
}

.properties-countdown__title {
  color: $extraOrange;
  position: relative;
  max-width: 200px;
  margin: 0 auto;
  @include fs(29);
  font-weight: 800;
}

.properties-countdown__counter {
  display: flex;
  justify-content: center;
  margin-bottom: 12px;
  transition: $transitionDefault;

  &.active {
    .properties-countdown__counter-item {
      opacity: 1;

      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        transform: translateY(0);
      }
    }
  }
}

.properties-countdown__counter-item {
  display: block;
  width: 65px;
  margin: 0 5px;
  transition: $transitionDefault;
  opacity: 0;

  &:nth-child(2) {
    transform: translateY(10px);
    transition-delay: 0.1s;
  }

  &:nth-child(3) {
    transform: translateY(20px);
    transition-delay: 0.2s;
  }

  &:nth-child(4) {
    transform: translateY(30px);
    transition-delay: 0.3s;
  }
}

.properties-countdown__number {
  display: block;
  height: 50px;
  line-height: 65px;
  @include fluid-type(font-size, $mediumSize, $largerSize, 20px, 37px);
  font-weight: 700;
}

.properties-countdown__text {
  @include fs(12);
}

.properties-countdown__info {
  position: relative;
  max-width: 260px;
  margin: 0 auto;
  @include fs(15);
  line-height: 1.4em;
}

// property

.property .box--black {
  font,
  span,
  p {
    color: $white !important;
  }
}

// video slideshow
.video-slideshow {
  mask-image: url("../images/video-slideshow-mask.svg");
  mask-size: cover;
  overflow: hidden;
  border-bottom-right-radius: $borderRadiusLarge;
  margin-right: -20px;
  float: right;
  max-width: 741px;

  @media #{$medium} {
    margin-right: -20px;
    margin-left: 20px;
  }

  @media #{$larger} {
    margin-right: -60px;
  }
}

.video-slideshow__slide {
  position: relative;

  + .video-slideshow__slide {
    display: none;
  }
}

.video-slideshow__link {
  backface-visibility: hidden;
  display: block;
  text-decoration: none;
  position: relative;
  -webkit-font-smoothing: antialiased;

  &:hover,
  &:focus {
    .video-slideshow__icon-play {
      transform: scale(1.1);

      &:before,
      .icon {
        transform: scale(1.2);
      }
    }
  }
}

.video-slideshow__visual {
  position: relative;
  transition: $transitionDefault;

  img {
    display: block;
    width: 100%;
  }

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba($accent, 0.35);
  }
}

.video-slideshow__content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
  flex-direction: column;
  padding-top: 20%;
  padding-left: 5%;
}

.video-slideshow__icon-play {
  backface-visibility: hidden;
  position: relative;
  width: 70px;
  height: 70px;
  background-color: rgba(255, 255, 255, 0.35);
  border-radius: 100%;
  overflow: hidden;
  text-indent: -100px;
  margin-bottom: 25px;
  transition: $transitionDefault;

  &:before,
  .icon {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transition: $transitionDefault;
  }

  &:before {
    content: "";
    background-color: #fff;
    width: 44px;
    height: 44px;
    border-radius: 100%;
    margin-left: -22px;
    margin-top: -22px;
  }

  .icon {
    margin-left: -5px;
    margin-top: -6px;
  }

  @media #{$medium} {
    width: 90px;
    height: 90px;
  }
}

.video-slideshow__text {
  backface-visibility: hidden;
  @include fluid-type(font-size, $mediumSize, $largerSize, 20px, 30px);
  max-width: 340px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
  line-height: 1.2em;
}

// keel
.keel {
  @include clearfix;
  text-align: center;
  background-color: #fff;
  padding: 50px 0 25px;

  @media #{$medium} {
    padding: 100px 0;
  }
}

.keel__logo {
  margin-bottom: 30px;

  @media #{$maxMedium} {
    img {
      max-width: 200px;
    }
  }

  @media #{$medium} {
    margin-bottom: 60px;
  }
}

.keel__rte {
  h2 {
    margin-bottom: 1.3em;
  }

  @media #{$medium} {
    @include fs(20);
  }
}

.keel__btns {
  .button {
    display: inline-block;
    float: none;
  }
}

/* =============================================================================
   PROPERTY
   ========================================================================== */
.property {
  margin-bottom: 20px;
}

.property__hero {
  margin-bottom: 10px;
  position: relative;
  border-radius: $borderRadiusLarge;
  overflow: hidden;

  .button--video-trigger {
    margin-bottom: $gutter / 2;

    @media #{$medium} {
      position: absolute;
      left: $gutter;
      bottom: $gutter * 1.75;
      margin-bottom: 0;
    }
  }

  @media #{$medium} {
    margin-bottom: 30px;
  }
}

.property__header {
  @include clearfix;
  padding: 20px;

  .grid {
    margin-bottom: 0;
  }

  @media #{$medium} {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 800;
    padding: 20px;

    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      backdrop-filter: blur(10px);
      -webkit-backdrop-filter: blur(10px);
      background-color: rgba(255, 255, 255, 0.9);
      z-index: -1;
    }
  }

  @media #{$large} {
    padding: 0;
    display: flex;
  }
}

.property__header-content {
  @media #{$large} {
    flex: 1;
    padding: 14px 30px 10px;
  }

  @media #{$larger} {
    display: flex;
    align-items: center;
  }

  @media #{$huge} {
    padding-left: 40px;
  }

  @media #{$massive} {
    padding-right: 40px;
  }
}

.property__header-title-wrapper {
  @media #{$medium} {
    display: flex;
    margin-bottom: 10px;
  }

  @media #{$larger} {
    flex: 1;
  }
}

.property__label {
  @extend .cta__label;
  margin: 0 0 30px;
}

.table__label {
  @extend .cta__label;
  margin: 0 0 0 20px;
  display: inline-block;
  float: none;
}

.property__label--option,
.property__label--contract {
  @extend .cta__label--option;
}

.property__label--sold {
  @extend .cta__label--sold;
}

.property__label--new {
  @extend .cta__label--new;
}

.property__title-wrapper {
  @media #{$medium} {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
}

.property__title-info {
  margin-left: 30px;
  @media #{$medium} {
    margin-bottom: 40px;
  }
  @media #{$mobile} {
    margin-left: 0;
  }
}

.property__title {
  font-weight: 400;
  @include fs(19);
  color: $black;
  margin-bottom: 15px;

  strong,
  div {
    font-weight: 700;
    display: block;
    @include fluid-type(font-size, $mediumSize, $largerSize, 24px, 32px);
    margin-bottom: 0;
    line-height: 1em;
  }

  @media #{$medium} {
    margin-bottom: 30px;

    strong,
    div {
      margin-bottom: 0;
    }
  }
}

.property__title-with-logo {
  @media #{$medium} {
    display: flex;
    justify-content: space-between;
  }
}

.property__title-logo {
  display: block;
  margin-bottom: 20px;

  &:hover,
  &:focus {
    opacity: 0.6;
  }

  img {
    width: 80px;
  }

  @media #{$medium} {
    margin-left: 20px;
    margin-top: 10px;
  }

  @media #{$huge} {
    img {
      width: 124px;
    }
  }
}

.property__features-circle {
  background-color: $accent;
  color: #fff;
  padding: 20px;
  z-index: 100;
  margin: 0 0 20px;
  @include fs(16);
  padding: 20px 20px 2px;
  text-align: center;

  p {
    margin-bottom: 18px;
  }

  @media #{$large} {
    padding: 20px 30px 0 30px;
    width: 350px;
    height: 350px;
    position: absolute;
    right: 30px;
    top: -350px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 100%;
    @include fs(19);
  }

  @media #{$larger} {
    right: 40px;
  }
}

.property__price {
  line-height: 1em;
  margin: 0;
  @include fluid-type(font-size, $mediumSize, $largerSize, 24px, 37px);

  br {
    display: none;
  }

  small {
    display: block;
    @include fs(16);
    margin: 15px 0 0;
  }
}

.property__features {
  @extend .unstyled;
  margin: 0;
  @include fs(14);

  li {
    display: inline-block;
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
  }

  .icon {
    margin-right: 5px;
  }

  @media #{$large} {
    float: left;
    margin-top: 5px;

    li {
      margin-right: 30px;
    }
  }

  @media #{$larger} {
    li {
      margin-right: 15px;
    }
  }

  @media #{$huge} {
    &:before {
      content: "";
      display: block;
      border-left: 1px solid #fff;
      height: 35px;
      float: left;
      margin: -8px 34px 0 10px;
    }

    li {
      margin-right: 30px;
    }
  }

  @media #{$massive} {
    &:before {
      margin-left: 47px;
      margin-right: 75px;
    }

    li {
      margin-right: 40px;
    }
  }
}

.property__contact-cta {
  @extend .button;
  @include fs(10);
  padding: 14px 15px;

  @media #{$medium} {
    position: absolute;
    bottom: 20px;
    right: 20px;
  }

  @media #{$large} {
    // fix to borders
    margin: 0;
    border-radius: 0;
    box-shadow: none;
    padding: 33px 30px;
    position: static;
    display: flex;
    align-items: center;

    .icon-mail {
      width: 20px;
      height: 16px;
    }

    &:hover,
    &:focus {
      transform: translateY(0);
    }
  }

  @media #{$huge} {
    padding-left: 40px;
    padding-right: 40px;
  }
}

// actions
.property__actions {
  text-transform: uppercase;
  @include fs(13);
  margin-bottom: 20px;
  display: flex;

  @media #{$medium} {
    margin-top: 50px;
  }
}

.property__links {
  margin-left: auto;
  margin-bottom: 0;
}

.property__reference {
  margin-bottom: 0;
}

.property__share {
  margin-left: 40px;
  a {
    margin-left: 8px;
    .icon {
      margin-bottom: 3px;
    }
  }
}

// description
.property__description {
  margin-bottom: 20px;

  p,
  ul,
  ol {
    + h2,
    + h3 {
      margin-top: 2em;
    }
  }

  p {
    margin-bottom: 1.5em;

    span {
      font-family: $defaultFont;
      @include fs(16);
      line-height: 1.5em;
    }
  }

  h3 {
    @include fs(18);
  }

  table {
    margin-bottom: 2.5em;
  }

  td,
  th {
    p {
      margin-bottom: 0;

      &:first-child {
        @include fs(16);
      }
    }
  }

  @media #{$medium} {
    margin-bottom: 80px;
    @include fs(18);

    h3 {
      @include fs(20);
    }

    td,
    th {
      p {
        &:first-child {
          @include fs(18);
        }
      }
    }
  }
}

.epc-dd {
  width: 50%;
}

.epc-bar {
  position: relative;
  display: inline-block;
  width: 110px;
  height: 10px;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#0cbf00+0,fff200+50,ff0000+100 */
  background: rgb(12, 191, 0); /* Old browsers */
  background: -moz-linear-gradient(
    left,
    rgba(12, 191, 0, 1) 0%,
    rgba(255, 242, 0, 1) 50%,
    rgba(255, 0, 0, 1) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    rgba(12, 191, 0, 1) 0%,
    rgba(255, 242, 0, 1) 50%,
    rgba(255, 0, 0, 1) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    rgba(12, 191, 0, 1) 0%,
    rgba(255, 242, 0, 1) 50%,
    rgba(255, 0, 0, 1) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.epc-bar__line {
  position: absolute;
  display: block;
  width: 2px;
  background: $black;
  left: 0%;
  top: 50%;
  height: 16px;
  margin-top: -8px;
}

.epc-value {
  display: inline-block;
  margin-right: 10px;
}

.epc-arrow {
  position: relative;
  padding: 0px 10px;
  background-color: #058b46;
  color: $white;
  min-width: 75px;
  text-align: right;
  font-weight: 700;
  text-transform: uppercase;

  &:after {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10.5px 0 10.5px 10px;
    border-color: transparent transparent transparent #058b46;
    position: absolute;
    top: 0;
    left: 100%;
  }

  &.epc-arrow--a-plus {
    background-color: #058b46;
    &:after {
      border-color: transparent transparent transparent #058b46;
    }
  }
  &.epc-arrow--a {
    background-color: #058b46;
    &:after {
      border-color: transparent transparent transparent #058b46;
    }
  }
  &.epc-arrow--b {
    background-color: #00a650;
    &:after {
      border-color: transparent transparent transparent #00a650;
    }
  }
  &.epc-arrow--c {
    background-color: #71bf47;
    &:after {
      border-color: transparent transparent transparent #71bf47;
    }
  }
  &.epc-arrow--d {
    background-color: #ffdd00;
    &:after {
      border-color: transparent transparent transparent #ffdd00;
    }
  }
  &.epc-arrow--e {
    background-color: #fdb813;
    &:after {
      border-color: transparent transparent transparent #fdb813;
    }
  }
  &.epc-arrow--f {
    background-color: #f57021;
    &:after {
      border-color: transparent transparent transparent #f57021;
    }
  }
  &.epc-arrow--g {
    background-color: #f01a26;
    &:after {
      border-color: transparent transparent transparent #f01a26;
    }
  }
}

.property__downloads {
  @extend .unstyled;
  @include clearfix;
  margin-bottom: 40px;

  li {
    margin-bottom: 15px;
  }

  .button {
    display: flex;
  }

  @media #{$medium} {
    margin-left: -20px;
    margin-bottom: 60px;

    li {
      padding-left: 20px;
      float: left;
    }
  }
}

.property-agent {
  margin: 20px 0;
  display: flex;
  align-items: center;

  .icons-list {
    margin-bottom: 0;
  }

  @media #{$medium} {
    margin: 40px 0;
  }
}

.property-agent__visual {
  width: 150px;
  margin-right: 15px;

  @media #{$medium} {
    width: 300px;
    margin-right: 25px;
  }
}

.property-agent__content {
  // padding-top: 30px;
  // width: 100%;
  // float: left;
  // clear: both;

  @media #{$medium} {
    padding-top: 20px;
    min-width: 250px;
  }
}

.thumbs-list {
  @extend .unstyled;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-left: -20px;

  li {
    width: 50%;
    padding-left: 20px;
    margin: 0;
  }

  @media #{$medium} {
    margin-left: -40px;

    li {
      padding-left: 40px;
      width: 25%;
    }
  }

  @media #{$larger} {
    li {
      width: 16.66%;
    }
  }
}

.thumbs-list__link {
  display: block;
  border-radius: $borderRadius;
  float: left;
  overflow: hidden;
  margin-bottom: 20px;
  position: relative;
  height: 0;
  width: 100%;
  padding-bottom: 100%;

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba($accent, 0.3);
    transition: $transitionDefault;
    opacity: 0;
  }

  &:hover,
  &:focus {
    &:after {
      opacity: 1;
    }
  }

  @media #{$medium} {
    margin-bottom: 40px;
  }
}

.thumbs-list__visual {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  img {
    object-fit: cover;
    font-family: "object-fit: cover;";
    width: 100%;
    height: 100%;
  }
}

/* property map */
.property-map {
  position: relative;
  display: block;
  text-decoration: none;
  border-radius: $borderRadius;
  overflow: hidden;
  margin: 0 0 40px;
  min-height: 240px;
  background-color: #fff;

  img {
    width: 100%;
  }

  &:hover,
  &:focus {
    transform: translateY(-20px);
  }

  @media #{$medium} {
    margin: 0;
  }
}

.jumbotron {
  .property-map {
    @media #{$maxMedium} {
      margin-left: -30px;
      margin-right: -30px;
      margin-bottom: 0;
    }
  }
}

.property-map__placeholder {
  display: block;
  background-color: #fafafa;
  height: 0;
  padding-bottom: 59.37%;
}

.property-map__marker {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -19px;
  transform: translateX(-50%);

  &:before,
  &:after {
    display: block;
    content: "";
    border-radius: 100%;
    position: absolute;
    top: 0;
    left: 50%;
  }

  &:before {
    width: 38px;
    height: 38px;
    margin-left: -19px;
    background-color: rgba(2, 63, 133, 0.25);
  }

  &:after {
    width: 18px;
    height: 18px;
    margin-left: -9px;
    margin-top: 10px;
    background-color: $accent;
  }
}

.property-map__label {
  margin-top: 55px;
  background-color: $accent;
  color: #fff;
  border-radius: $borderRadius;
  padding: 12px 20px;
  text-transform: uppercase;
  @include fs(11);
  letter-spacing: 1px;
  position: relative;
  min-width: 210px;
  line-height: 1.5em;
  text-align: center;

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: -8px;
    margin-left: -8px;
    left: 50%;
    border-bottom: 8px solid $accent;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
  }
}

.property-location-body {
  position: relative;
  overflow: hidden;
  transition: $transitionDefault;

  &:after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 5;
    height: 200px;
    background: linear-gradient(transparent, #fcfcfc);
    transition: $transitionDefault;
  }
}

.property-location-body--open {
  padding-bottom: 60px;

  &:after {
    opacity: 0;
  }
}

.property-location-body__more {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;

  &:hover,
  &:focus {
    transform: translateX(-50%) translateY(-5px);
  }
}

/* =============================================================================
   PROJECTS (custom pages
   ========================================================================== */

.project {
  --background-color: #fff;
  --text-color: #{$brown};
  background-color: var(--background-color);
  color: var(--text-color);
  font-family: $bureauSans;
  font-weight: 300;
  font-size: 18px;
}

.project__logo {
  width: 100%;
  max-width: 360px;

  @media #{$large} {
    max-width: none;
    width: clamp(400px, 30vw, 600px);
  }
}

.project__header {
  display: flex;
  flex-direction: column;
  gap: $gutter;
  margin-bottom: $gutter;

  div {
    flex-shrink: 0;
  }

  @media #{$large} {
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: $gutter * 2;
  }
}

.project__title {
  font-size: clamp(32px, 5vw, 100px);
  font-weight: 500;
  margin-bottom: 0;
  color: var(--text-color);

  @media #{$medium} {
    font-size: clamp(42px, 6vw, 120px);
  }
}

.project__subtitle {
  margin-bottom: 0;
  color: var(--text-color);

  &.project__subtitle--serif {
    font-weight: 500;
    font-family: $bureauSerif;
  }

  + .project__subtitle {
    margin-top: 0.3em;
  }

  &:not(.h2) {
    @media #{$large} {
      font-size: 19px;
    }
  }

  .dot {
    display: inline-block;
    width: 10px;
    height: 10px;
    background-color: $accent;
    border-radius: 100%;
  }

  @media #{$large} {
    text-align: right;
  }
}

.project__main-visual {
  img {
    border-radius: $borderRadiusMedium $borderRadiusMedium 0 0;
  }
}

.project__heading {
  font-size: clamp(32px, 3vw, 48px);
  font-weight: 300;
  line-height: 1.1em;
  color: var(--text-color);

  strong {
    font-family: $bureauSerif;
    font-weight: 500;
  }

  p {
    line-height: inherit;
    margin-bottom: 0;
  }

  &.project__heading--giant {
    @media #{$medium} {
      line-height: 1.1em;
      max-width: 20ch;
      font-size: clamp(42px, 3.5vw, 86px);
    }
  }
}

.project__slideshow {
  position: relative;

  .slick-prev,
  .slick-next {
    background-color: var(--background-color);
    color: var(--text-color);
  }
}

.project-slideshow__slide {
  display: inline-block;
  will-change: transform;
  transition: $transitionDefault;

  &.slick-slide {
    opacity: 1;
    transform: scale(0.9);
  }

  &.slick-current {
    opacity: 1;
    transform: scale(1);
  }
}

.project-slideshow__img {
  img {
    border-radius: $borderRadiusMedium;
    height: 270px;
    display: inline-block;
    transition: $transitionDefault;
  }

  @media #{$medium} {
    img {
      height: 600px;
    }
  }
}

.project-units {
  display: flex;
  flex-direction: column;
  gap: $gutter;

  .accordion {
    border-color: rgba($black, 0.4);
    margin-bottom: 30px;
  }

  .accordion__title {
    position: relative;
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: 400;
    padding: 16px 0;
    margin-bottom: 0;
    color: $black;

    &:before {
      content: "";
      display: inline-block;
      width: 12px;
      height: 12px;
      margin-right: $gutter / 4;
      margin-bottom: 1px;
      background-color: $accent;
      border-radius: 100%;
    }

    &::after {
      background-image: url("../images/caret.svg");
      opacity: 1;
      margin-top: 0;
      margin-left: auto;
    }
  }

  .accordion__content {
    font-size: inherit;

    ul,
    ol {
      list-style-position: inside;
    }

    .rte {
      padding-left: $gutter * 0.5;
    }
  }

  @media #{$small2} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(3, auto);
    grid-template-areas:
      "header visual"
      "info info"
      "slider slider";
    gap: $gutter;
  }

  @media #{$large} {
    grid-template-columns: 5fr 1fr 6fr;
    grid-template-rows: repeat(3, auto);
    grid-template-areas:
      "header header ."
      "visual visual info"
      "visual visual slider";
    gap: $gutter * 1.5 $gutter;
  }

  @media #{$huge} {
    gap: $gutter * 2 $gutter;
  }
}

.project-units__header {
  grid-area: header;
  max-width: 45ch;
  margin: auto;

  @media #{$large} {
    margin: 0 auto;
  }
}

.project-units__main-visual {
  grid-area: visual;

  img {
    width: 100%;
  }
}

.project-units__info {
  grid-area: info;
  // padding: 0 $gutter;

  @media #{$large} {
    padding: 0 $gutter;
  }
}

.project-units__slider {
  position: relative;
  grid-area: slider;
  border-radius: $borderRadiusMedium;

  img {
    width: 100%;
  }

  .slick-prev,
  .slick-next {
    background-color: var(--background-color);
    color: var(--text-color);
  }

  .slick-prev {
    left: 0;
    transform: translateX(-50%);

    @media #{$huge} {
      left: 0;
    }
  }

  .slick-next {
    right: 0;
    transform: translateX(50%);

    @media #{$huge} {
      right: 0;
    }
  }

  .slick-list {
    border-radius: $borderRadiusMedium;
  }

  .slick-dots {
    transform: translateY(-100%);
  }

  .project-units-slider__slide {
    display: inline-block;
    transition: $transitionDefault;

    &.slick-slide {
      opacity: 1;
    }

    &.slick-current {
      opacity: 1;
    }
  }

  .project-units-slider__img {
    img {
      border-radius: $borderRadiusMedium;
      display: inline-block;
    }
  }

  @media #{$large} {
    grid-column: 2 / 4;
  }
}

.status-badge {
  font-family: $bureauSerif;
  @include fs(14);
  font-weight: 500;
  display: inline-block;
  padding: 6px 8px;
  border-radius: 4px;
  background-color: $accent;
  color: $white;
  margin: 0 8px;
  line-height: 1;
}

/* =============================================================================
   OVERVIEW PROPERTIES
   ========================================================================== */
.locations-form {
  margin-bottom: 20px;
  position: relative;
  background-color: #fff;
  height: 55px;
  border-radius: 10px;

  .select2-container--default
    .select2-selection--multiple
    .select2-selection__choice {
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    max-width: 100%;
  }

  .select2-container .select2-search--inline .select2-search__field,
  .select2-container--default
    .select2-selection--multiple
    .select2-selection__choice {
    padding-left: 50px;
  }

  label {
    width: 18px;
    height: 18px;
    background: #fff url("../images/search.svg") top left no-repeat;
    background-size: 18px 18px;
    display: block;
    position: absolute;
    top: 18px;
    left: 16px;
    overflow: hidden;
    margin: 0;
    text-indent: -500px;
    z-index: 100;
  }

  @media #{$medium} {
    width: 540px;
  }

  @media #{$large} {
    width: 240px;
    margin-bottom: 0;
    margin-right: 10px;
  }

  @media #{$larger} {
    width: 320px;
  }

  @media #{$huge} {
    width: 400px;
    margin-right: 20px;
  }
}

.button-show-filters {
  .icon {
    transition: $transitionDefault;
  }

  &.active {
    .icon {
      transform: rotate(90deg) translateY(-5px);
    }
  }

  @media #{$maxMedium} {
    padding: $gutter / 2 8px $gutter / 2 $gutter / 3;
  }
}

.js-show-filters {
  @media #{$medium} {
    display: none;
  }
}

.filters-form {
  @include clearfix;
  margin-bottom: 40px;

  fieldset {
    margin-bottom: 20px;

    ul {
      margin-bottom: 0;
    }
  }

  .checkbox label {
    @include fs(15);
  }

  .range {
    background-color: #fff;
    border-radius: 10px;
  }

  @media #{$medium} {
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
  }

  @media #{$large} {
    flex-wrap: nowrap;
  }

  @media #{$maxMedium} {
    display: none;
  }
}

.filters-form__buttons {
  .button {
    height: 55px;

    &:last-child {
      margin-right: 0;
    }
  }

  button[type="submit"] {
    width: 100%;

    .icon {
      margin: -3px 0 0 -3px;
    }
  }

  @media #{$medium} {
    display: flex;
    align-items: flex-start;

    button[type="submit"] {
      width: 55px;
    }
  }
}

.filters-form__divider {
  &:before {
    content: "";
    display: block;
    position: relative;
    margin: 10px 0 20px;
    border-top: 1px solid #efefef;
  }
}

.expandable {
  position: relative;
  width: 100%;

  @media #{$medium} {
    max-width: 265px;
  }

  @media #{$large} {
    max-width: 150px;
  }

  @media #{$larger} {
    max-width: 230px;
  }

  @media #{$huge} {
    max-width: 310px;
  }
}

.expandable__title {
  position: relative;
  display: block;
  background-color: #fff;
  height: 55px;
  padding: 20px 35px 20px 15px;
  margin: 0;
  width: 100%;
  cursor: pointer;
  border-radius: 10px;

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    right: 15px;
    width: 10px;
    height: 5px;
    margin-top: -2px;
    background: url("../images/caret.svg") center no-repeat;
    background-size: contain;
    transition: $transitionDefault;
  }
}

.expandable__title--open {
  &:after {
    transform: rotate(180deg);
  }

  + .expandable__content {
    display: block;
    opacity: 1;
    visibility: visible;
  }
}

.expandable__content {
  margin: $gutter / 2 0 0 $gutter / 3;

  p {
    margin: 0;
  }

  @media #{$medium} {
    margin-top: 0;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    border-top: 1px solid $grayLight;
    background-color: #fff;
    padding: 15px 15px 5px;
    z-index: 1000;
    display: none;
    transition: $transitionDefault;
    opacity: 0;
    visibility: hidden;
    border-radius: 10px;
  }
}

.overview-header {
  @include clearfix;
  padding: 20px 0;
  background-color: $grayLight;
  margin-bottom: 20px;

  @media #{$medium} {
    padding: 50px 0 110px;
    margin-bottom: -90px;
  }
}

.overview-header__filters {
  @media #{$medium} {
    display: flex;
    flex-wrap: wrap;
  }

  @media #{$large} {
    flex-wrap: nowrap;
  }
}

.overview-header__filters fieldset,
.filters-form__buttons .button {
  margin-right: 10px;

  @media #{$huge} {
    margin-right: 20px;
  }
}

.overview-header__content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.overview-header__title {
  @include fs(14);
  color: #323e4d;
  margin: 0;

  @media #{$medium} {
    @include fs(18);
    flex: 1;
    margin: 2px 0 0;
  }
}

.overview-header__btns {
  .switch,
  .button {
    float: left;
    clear: none;
    margin: 0;

    + .switch,
    + .button {
      margin-left: 15px;
    }
  }

  .switch__btn {
    background-color: $grayLight;
    color: #000;

    .icon {
      fill: #000;
    }
  }

  @media #{$medium} {
    .switch,
    .button {
      + .switch,
      + .button {
        margin-left: 20px;
      }
    }
  }
}

.overview-toolbar {
  @media #{$medium} {
    display: flex;
    margin-bottom: 15px;
  }
}

.selected-filters {
  @include clearfix;

  @media #{$maxMedium} {
    margin-bottom: 10px;
  }

  @media #{$medium} {
    flex: 1;
  }
}

.selected-filters__title {
  display: inline-block;
  @include fs(16);
  color: #b4b8be;
  margin: 0 23px 10px 0;
}

.selected-filters__list {
  @extend .unstyled;
  display: inline-block;
  margin: 0;
  vertical-align: top;

  li {
    display: inline-block;
    margin: 0;
  }
}

.selected-filters__item {
  margin: 0 10px 10px 0;
  background-color: #fff;
  padding: 8px 11px;
  @include fs(11);
  box-shadow: none;
  color: #323e4d;

  svg {
    width: 8px;
    height: 8px;
    fill: $grayDark;
    transition: fill 0.4s $easeInOut;
    margin-left: 5px;
    margin-right: 0;
  }

  &:hover,
  &:focus {
    transform: none;
    color: #000;
    background-color: #fff;

    svg {
      fill: $accent;
    }
  }
}

.sort-dropdown {
  display: flex;
  border-left: 1px solid #000;
  height: 25px;
  padding: 2px 0 0 20px;

  @media #{$maxMedium} {
    margin-bottom: 15px;
  }
}

.sort-dropdown__label {
  color: #b4b8be;
  margin: 0 10px 0 0;
}

.sort-dropdown__select {
  @include fs(16);
  padding: 0 23px 0 0;
  width: auto;
  border: 0;
  height: 24px;
  background-position: center right;
  background-color: transparent;
  text-transform: uppercase;
}

/* overview results */
.overview-results,
#overview-map,
.projects-results {
  position: relative;

  &:before,
  &:after {
    content: "";
    display: block;
    position: absolute;
    z-index: 900;
    transition: $transitionDefault;
    opacity: 0;
    visibility: hidden;
  }

  &:before {
    top: 0;
    left: 0;
    right: -20px;
    bottom: 0;
    background-color: rgba(red(#fafafa), green(#fafafa), blue(#fafafa), 0.6);
    min-height: 650px;
  }

  &:after {
    width: 200px;
    height: 200px;
    background: url("../images/loader.svg") center center no-repeat;
    top: 150px;
    left: 50%;
    margin-left: -100px;
  }

  &.js-is-loading {
    &:before,
    &:after {
      opacity: 1;
      visibility: visible;
    }
  }
}

.overview-results__static-map {
  margin-bottom: 30px;

  @media #{$medium} {
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    padding-left: 12px;
    z-index: 500;
  }

  @media #{$larger} {
    width: 66.66%;
  }

  @media #{$huge} {
    width: 50%;
  }
}

.overview-results__static-map-link {
  position: relative;
  display: block;
  overflow: hidden;
  border-radius: $borderRadius;
  height: 0;
  padding-bottom: 100%;

  &:hover,
  &:focus {
    transform: translateY(-20px);

    .overview-results__static-map-text {
      transform: translateX(-50%) translateY(-5px);
      color: $accentHover;
    }
  }

  @media #{$medium} {
    padding-bottom: 59.55%;
    padding-top: 107px; // min height of content
  }

  @media #{$larger} {
    padding-top: 224px; // 107 + 107 + 10
  }

  @media #{$massive} {
    padding-top: 240px; // 115 + 115 + 10
  }
}

.overview-results__static-map-img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: $transitionDefault;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: bottom;
    font-family: "object-fit: cover; object-position: bottom;";
  }
}

.overview-results__static-map-text {
  @extend .button;
  @extend .button--white;
  position: absolute;
  bottom: 15px;
  left: 50%;
  z-index: 100;
  transform: translateX(-50%);

  &:hover,
  &:focus {
    transform: translateX(-50%);
  }

  @media #{$medium} {
    bottom: 30px;
  }
}

.overview-results__list {
  .grid__item {
    margin-bottom: 20px;
  }
}

.overview-results--has-static-map {
  @media #{$medium} {
    .overview-results__list {
      .grid__item {
        &:nth-child(2n + 2) {
          clear: left;
        }
      }
    }
  }

  @media #{$larger} {
    .list-view {
      min-height: calc((100vw - 80px) * 0.5);
    }

    .overview-results__list {
      .grid__item {
        &:nth-child(2n + 2) {
          clear: none;
        }

        &:nth-child(2),
        &:nth-child(3n + 3) {
          clear: left;
        }
      }
    }
  }

  @media #{$huge} {
    .list-view {
      min-height: calc((100vw - 80px) * 0.4);
    }

    .overview-results__list {
      .grid__item {
        &:nth-child(2),
        &:nth-child(3n + 3) {
          clear: none;
        }

        &:nth-child(3),
        &:nth-child(4n + 5) {
          clear: left;
        }
      }
    }
  }

  @media #{$massive} {
    .list-view {
      min-height: 650px;
    }
  }
}

// clear floats
.overview-results--uniform {
  @media #{$medium} {
    .grid__item {
      &:nth-child(2n + 3) {
        clear: left;
      }
    }
  }

  @media #{$larger} {
    .grid__item {
      &:nth-child(2n + 3) {
        clear: none;
      }

      &:nth-child(3n + 4) {
        clear: left;
      }
    }
  }
}

.pagination {
  text-align: center;
  list-style-type: none;
  margin: 0;
  @include fs(16);

  li {
    display: inline-block;
    vertical-align: top;
    margin-bottom: 10px;
  }

  span,
  a {
    text-decoration: none;
    display: block;
    height: 42px;
    line-height: 42px;
    padding: 0 8px;
    min-width: 42px;
    text-align: center;
    border-radius: $borderRadius;
  }

  a {
    &:hover,
    &:focus {
      background-color: #fff;
    }
  }

  a[rel="prev"],
  a[rel="next"] {
    background-color: $accent;
    color: #fff;

    &:hover,
    &:focus {
      background-color: $accentHover;
    }
  }

  .active span {
    background-color: $accent;
    color: #fff;
    font-weight: 600;
  }

  .disabled {
    cursor: default;
    opacity: 0.5;
  }
}

/* overview sold properties */
.overview-sold-properties {
  margin-top: 40px;

  @media #{$medium} {
    margin-top: 75px;
  }
}

/* stay informed jumbotron */
.stay-informed {
  text-align: center;

  @media #{$larger} {
    .jumbotron__content {
      padding: 85px 120px;
      max-width: 1450px;
    }
  }
}

.stay-informed__title {
  line-height: 2.3em;
}

.stay-informed__form {
  margin-bottom: 20px;
  @include clearfix;

  @media #{$maxMedium} {
    .input-group {
      margin-bottom: 20px;
    }
  }

  @media #{$medium} {
    max-width: 780px;
    position: relative;
    margin: 0 auto 40px;
    display: flex;

    .input-group {
      flex: 1;
      padding-right: 30px;
    }
  }
}

/* region */
.region {
  .section-header {
    padding-left: 0;
    padding-right: 0;
  }
}

.region__body {
  strong {
    color: $accent;
  }

  @media #{$medium} {
    margin-bottom: -40px;
  }

  @media #{$huge} {
    @include fs(18);
  }
}

//map overview

/* Map */
#overview-map {
  position: relative;
  height: 400px;
  margin-bottom: 15px;
  background: #eaeaea;
  border-radius: $borderRadius;
  overflow: hidden;

  @media #{$medium} {
    height: 80vh;
    height: calc(100vh - 150px);
    margin-bottom: 0;
    min-height: 500px;

    &.is-fixed {
      position: fixed;
      top: 125px;
      width: 70%;
      width: calc(75% - 75px);
      max-width: 1385px;
    }

    &.is-bottom {
      position: absolute;
      bottom: 0;
      top: inherit;
      width: calc(75% - 72px);
    }
  }

  @media #{$huge} {
    &.is-fixed {
      width: calc(80% - 75px);
    }

    &.is-bottom {
      width: calc(80% - 79px);
    }
  }
}

#overview-map-holder {
  height: 100%;
  width: 100%;
}

.infoBox:before,
.infoBox--contact:before {
  content: " ";
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  bottom: -14px;
  left: 70px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid $accent;
}

.infoBox--contact {
  &:before {
    border: 10px solid transparent;
    border-left-color: #fff;
    border-right: 0;
  }
}

.infoBox--detail {
  .info-box-img {
    width: 240px;
    height: 150px;
  }

  &:before {
    left: 110px;
  }
}

.infoBox--unavailable {
  .info-box-item {
    background-color: #b5b5b5;
  }

  &:before {
    border-top: 10px solid #b5b5b5;
  }
}

.info-box-item {
  //#font > .default(12, 500, 1.5em);
  background: $accent;
  color: #fff;
  text-align: center;
  overflow: hidden;
  text-transform: uppercase;

  p {
    margin: 10px 0;
  }

  a {
    color: #fff;

    &:hover,
    &:focus {
      color: #efefef;
    }
  }

  .title {
    font-weight: 600;
    letter-spacing: 1px;
    height: 18px;
    display: block;
  }

  .description {
    @include clearfix;
    text-transform: none;
    display: block;
    padding: 0 10px 5px;
    margin-top: -10px;
  }
}

.info-box-img {
  width: 160px;
  height: 120px;
  overflow: hidden;
  background: center center no-repeat;
  background-size: cover;
}

// Modaal cta
.js-cta-modaal {
  display: none;
}

.modaal-cta {
  text-align: center;
}

.modaal-cta__title {
  @include fluid-type(font-size, $mediumSize, $largerSize, 20px, 25px);
  margin-bottom: 20px;
}

.modaal-cta__body {
  text-align: center;
  margin-bottom: 20px;

  .button {
    display: inline-block;
    float: none;
  }
}

/* =============================================================================
   PROJECTS PAGE
   ========================================================================== */

.projects-results {
  margin-bottom: 20px;

  @media #{$medium} {
    margin-bottom: 40px;
  }
}

/* =============================================================================
   SELLING PAGE
   ========================================================================== */
.cta-form {
  margin-bottom: 35px;

  .jumbotron__content {
    text-align: center;
  }

  form {
    @include clearfix;
  }

  @media #{$medium} {
    .jumbotron__content {
      padding: 65px 60px 290px;
    }

    .note {
      margin-top: -270px;
    }
  }
}

.cta-form__choices {
  color: #fff;
  margin-bottom: 40px;

  legend {
    color: #fff;
  }

  @media #{$medium} {
    margin-top: -270px;
    margin-bottom: 70px;
  }
}

.cta-form__choices-content {
  @media #{$medium} {
    display: flex;
    align-items: center;
    justify-content: center;

    legend,
    .checkbox-button {
      margin: 0 15px;
    }
  }
}

.cta-form__body {
  @include clearfix;
  background-color: #fff;

  legend {
    color: $accent;
  }

  fieldset {
    &:last-of-type {
      .grid {
        margin-bottom: 0;
      }
    }
  }

  @media #{$medium} {
    padding: 40px;
    border-radius: $borderRadiusLarge;

    fieldset {
      + fieldset {
        margin-top: 20px;
      }
    }
  }

  @media #{$huge} {
    padding: 60px 90px 0;
  }
}

.how-we-work-grid {
  > * {
    align-items: flex-start;
  }
}

.how-we-work-navigation,
.jobs-list-navigation {
  counter-reset: howWeWorkNav;
  list-style: none;
  margin-left: 0;
  padding-left: 0;
  height: auto;
  width: 100%;
  //background-color: lavender;
  display: block;
  margin-bottom: 40px;

  > li {
    counter-increment: howWeWorkNav;
    margin-bottom: 15px;
    @include display-font();
    @include fs(18);
    opacity: 0.5;
    transition: $transitionDefault;

    @media #{$medium} {
      @include fs(21);
    }

    @media #{$larger} {
      margin-bottom: 40px;
    }

    &.active,
    &:hover,
    &:focus,
    &:active {
      opacity: 1;

      &:before {
        color: $accent;
      }
    }

    .jobs-list-navigation__sublist {
      list-style: none;
      margin-top: 20px;
      margin-left: 25px;
    }

    a {
      color: $accent;
      display: inline-block;
      text-decoration: none;
      margin-top: -2px;

      &:hover,
      &:focus,
      &:active {
        opacity: 1;

        &:before {
          color: $accent;
        }
      }
    }

    .jobs-list__subtitle-wrap,
    .jobs-list__title-wrap,
    .how-we-work__title-wrap {
      display: flex;
      align-items: flex-start;
    }

    .jobs-list__subtitle,
    .how-we-work__subtitle {
      &:before {
        content: "›";
        @include fs(25);
        color: $accent;
        display: inline-block;
        margin-right: 10px;
      }
    }

    .jobs-list__title,
    .how-we-work__title {
      display: flex;
      align-items: flex-start;
      &:before {
        height: 40px;
        color: $accent;
        content: counter(howWeWorkNav) ".";
        display: inline-block;
        margin-right: 8px;
        transition: $transitionDefault;
      }
    }
  }

  @media #{$medium} {
    display: block;
    padding-left: 30px;
  }

  @media #{$huge} {
    padding-left: 0;
  }
}

.jobs-list__body__subsection {
  transition: $transitionDefault;
  margin-bottom: 40px;

  /*&.active {
    opacity: 1;
  }*/
  @media #{$medium} {
    //opacity: 0.5;
    margin-bottom: 80px;
  }
}

.how-we-work,
.jobs-list {
  counter-reset: howWeWork;
  list-style: none;
  margin-left: 0;
  padding-left: 0;
  margin-bottom: 0;

  h3 {
    visibility: visible !important;
    margin-bottom: 10px;
    @include fs(18);
    display: flex;
    align-items: flex-start;

    &:before {
      color: $accent;
      content: counter(howWeWork) ".";
      display: inline-block;
      margin-right: 8px;
      transition: $transitionDefault;
    }
  }

  > li {
    counter-increment: howWeWork;

    &:after {
      display: block;
      content: "";
      height: 1px;
      width: 100%;
      background-color: #d9d9d9;
      margin: 45px 0;
    }

    &:last-child {
      &:after {
        content: none;
      }
    }

    &.active {
      opacity: 1;
    }
  }

  @media #{$medium} {
    margin-right: 30px;

    h3 {
      display: none;
      @include fs(21);
    }

    > li {
      // opacity: 0.5;

      &:after {
        margin: 70px 0;
      }
    }
  }

  @media #{$huge} {
    margin-right: 0;
  }
}

.how-we-work__body,
.jobs-list__body {
  margin-bottom: 0;
  @include fs(16);

  @media #{$medium} {
    @include fs(18);
  }

  @media #{$huge} {
    @include fs(20);
  }

  ul {
    list-style: none;
    margin-left: 0;
    padding-left: 0;
    li {
      position: relative;
      padding-left: 18px;

      &:before {
        content: "›";
        @include fs(25);
        color: $accent;
        position: absolute;
        top: -2px;
        left: 0;
      }
    }
  }
}

.how-we-work__title,
.jobs-list__title {
  margin-bottom: 0;
  font-weight: 600;
  @include fs(18);
  @include lh(28);

  @media #{$huge} {
    @include fs(21);
  }
}
.how-we-work__subtitle,
.jobs-list__subtitle {
  margin-bottom: 0;
  //font-weight: 600;
  @include fs(16);
  display: inline-block;
}

.client-quote-slider {
  max-width: 1250px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  position: relative;

  .slick-list {
    width: 100%;
  }

  .slick-arrow {
    width: 40px;
    height: 40px;
    background-color: white;
    padding: 10px;
    position: absolute;
    top: 150px;
    transform: translateY(-50%);
    border-radius: 100%;
    z-index: 11;

    &:hover,
    &:focus,
    &:active {
      opacity: 0.7;
    }

    img {
      width: auto;
    }

    &.slick-arrow--left {
      left: 10px;
    }

    &.slick-arrow--right {
      right: 10px;
    }

    @media #{$medium} {
      top: 40%;
    }
  }
}

.client-quote {
  width: 100%;
  margin-right: 20px;
  margin-left: 20px;
  outline: 0;

  .grid {
    width: 100%;
    margin-bottom: 0;

    @media #{$mobile} {
      margin-left: 0;
    }

    .grid__item {
      @media #{$mobile} {
        padding-left: 0;
      }
    }
  }

  @media #{$medium} {
    display: inline-block;
    margin-left: 80px;
    margin-right: 80px;

    .grid {
      display: flex;
      align-items: center;
    }

    .button {
      margin-bottom: 0;
    }
  }
}

.quote-character {
  display: none;
}

.client-quote__quote {
  @include fs(18);
  @include display-font;
  margin-bottom: 20px;
  text-align: center;

  p {
    display: inline-block;
  }

  &:after,
  &:before {
    content: "“";
    display: inline-block;
    color: $accent;
    @include default-font(25);
    line-height: 1em;
    margin-top: 5px;
    position: relative;
    top: 6px;
  }

  &:after {
    content: "”";
  }

  &:before {
    margin-right: -5px;
  }

  &:after {
    margin-left: -5px;
  }

  @media #{$medium} {
    // text-align: left;
    @include fs(21);

    &:after,
    &:before {
      @include fs(36);
    }
  }
}

.client-quote__visual {
  width: 100%;
  height: 300px;

  border-radius: 10px;
  background-size: cover;
  background-position: center;
  margin-bottom: 20px;

  @media #{$medium} {
    height: 0;
    padding-bottom: 100%;
    margin-bottom: 0;
  }
}

.client-quote__address {
  margin: 0 0 30px;
  text-align: center;
}

/* =============================================================================
   ACCOUNT
   ========================================================================== */

.property-views {
  position: relative;
  @include clearfix;
  list-style: none;
  text-align: center;
  max-width: 980px;
  margin: 0 auto;

  li {
    margin-bottom: 20px;
  }

  @media #{$medium} {
    margin-top: 40px;

    li {
      float: left;
      width: 25%;
      padding-left: 20px;
    }
  }
}

.property-views__count {
  display: block;
  margin-bottom: 15px;
  @include fluid-type(font-size, $mediumSize, $largerSize, 20px, 40px);
  color: $accent;
}

.property-views__label {
  text-transform: uppercase;
  @include fs(14);
}

/* =================================================
   Contact
   ================================================= */
.jumbotron--contact {
  margin-bottom: 40px;

  .jumbotron__content {
    @media #{$massive} {
      padding-left: 0;
    }
  }
}

.link-on-visual {
  color: $white;
}

.contact-grid {
  // @extend .equal-h;
  overflow: visible;
  @include clearfix;
  // margin-bottom: 40px;
  //align-items: center;
}

.contact-introduction {
  @include fs(21);
  @include optimize-font;
  margin-bottom: auto;
  margin-right: auto;
  color: $white;
  margin-top: 50px;

  a {
    position: relative;
    text-decoration: none;

    &:after {
      position: absolute;
      left: 0;
      bottom: 0em;
      content: "";
      height: 1px;
      width: 100%;
      background-color: $accent;
      display: block;
    }
  }

  @media #{$medium} {
    @include fs(25);
    @include lh(56);
  }

  @media #{$large} {
    margin-top: 250px;
    transform: translateY(-50%);
  }
}

.link-with-icon {
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  &:hover,
  &:focus,
  &:active {
    color: $white;

    .go-to-arrow {
      background-color: $accent;
    }

    svg {
      fill: $accent;
    }
  }
}

.contact-cta-data {
  @include fs(16);
  @include optimize-font;
  color: $white;

  &:hover,
  &:focus,
  &:active {
    color: $white;

    .go-to-arrow {
      background-color: $accent;
    }

    svg {
      fill: $accent;
    }
  }

  @media #{$large} {
    position: absolute;
    bottom: 0;
    margin-bottom: 50px;
  }
}

.go-to-arrow {
  transition: $transitionDefault;
  background: transparent;
  border: 2px solid $accent;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: inline-block;
  text-align: center;
  margin-right: 10px;
  // padding: 5px;

  svg {
    transition: $transitionDefault;
    /* height: 19px;
    width: 14px;*/
    transform: scale(0.8);
    margin-top: 0px;
    fill: white;
    display: inline-block;
  }
}

/*.go-to-arrow--left {
  svg {
    margin-top: 0px;
  }
}*/

.contact-data__section {
  margin-bottom: 40px;
  @include fs(18);
  a {
    text-decoration: none;
  }
}
.contact-data {
  @media #{$large} {
    margin-top: 200px;
  }

  strong {
    font-weight: 800;
  }

  h4,
  .h4 {
    font-weight: 600;
    @media #{$medium} {
      @include fs(19);
      margin-bottom: 40px;
    }
  }

  p {
    margin-bottom: 5px;
  }

  ul {
    list-style: none;
    margin-left: 0;
    padding-left: 0;

    .icon {
      margin-right: 5px;
    }

    a {
      text-decoration: none;
      color: $accent;
    }
  }

  > .grid {
    > .grid__item {
      &:first-child {
        @media #{$medium} {
          border-right: 1px solid rgba(#707070, 0.25);
        }
        &:after {
        }
      }
    }
  }
}

.office {
}

.office__visual {
  margin-bottom: 20px;

  @media #{$medium} {
    margin-bottom: 45px;
  }
}

.office__title {
  @include fs(20);
  margin-bottom: 10px;

  strong {
    font-weight: 700;
  }

  @media #{$large} {
    @include fs(25);
  }
}

.office__link {
  position: relative;
  padding-left: 30px;
  text-decoration: none;
  display: block;
  line-height: 1.5em;
  margin-bottom: 10px;

  .icon {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.map--office {
  // position: relative;

  width: 100%;
  //height: 380px;

  //box-shadow: 0 20px 50px rgba(19,25,32,0.14);
  border-radius: 10px;

  @media #{$large} {
    margin-top: -90px;
    margin-bottom: 160px;
  }
}

.agents-grid {
  @media #{$medium} {
    .grid__item {
      &:nth-child(3n + 4) {
        clear: left;
      }
    }
  }
}

/* =================================================
 About
   ================================================= */
.usp-list {
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0 0 -#{$gutter} -#{$gutter};
}

.usp-list__item {
  position: relative;
  text-align: left;
  padding: $gutter;
  background: $grayLight;
  margin-left: $gutter;
  margin-bottom: $gutter;
  width: calc(100% - $gutter);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  gap: $gutter;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px;
  overflow: hidden;

  @media #{$medium} {
    padding: $gutter * 1.5;
    width: calc(50% - #{$gutter});
  }
}

.usp-list__visual {
  height: 125px;
  width: 125px;
  display: flex;
  justify-content: center;
  align-content: center;
  position: relative;

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin-left: 0;
    border-radius: 100%;
    background-color: $white;
  }

  @media #{$medium} {
    height: 175px;
    width: 175px;
  }

  img {
    z-index: 1;
  }
}

.usp-list__content {
  // max-width: 20ch;
  min-width: 15ch;
  flex: 1;
}

.usp-list__title {
  font-weight: 700;
  @include fs(24);
  color: $extraOrange;
  text-align: left;
}

.team-grid {
  @media #{$medium} {
    margin-top: 90px;

    .grid__item:nth-child(3n + 4) {
      clear: left;
    }
  }

  @media #{$huge} {
    .grid__item:nth-child(3n + 4) {
      clear: none;
    }

    .grid__item:nth-child(4n + 5) {
      clear: left;
    }
  }
}

/* =================================================
 Sales
   ================================================= */
.visual-rounded {
  display: block;
  position: relative;
  margin: 60px 0 0;

  img {
    border-radius: 100%;
    overflow: hidden;
    margin-bottom: 25px;
  }

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: -360px;
    z-index: -1;
    left: -70px;
    width: 1040px;
    height: 1040px;
    background-color: #fafafa;
    border-radius: 100%;
  }

  @media #{$medium} {
    margin: 0;
  }

  @media #{$huge} {
    img {
      margin-bottom: 50px;
    }
  }
}

.visual-rounded--right-top {
  &:before {
    left: inherit;
    right: -145px;
    top: -90px;
  }
}

.check-list {
  border-top: 1px solid #ebebeb;
  list-style: none;
  margin: 45px 0;
  font-weight: 700;
  color: $accent;

  li {
    position: relative;
    padding: 25px 0 25px 65px;
    border-bottom: 1px solid #ebebeb;

    &:before,
    &:after {
      position: absolute;
      content: "";
      display: block;
    }

    &:before {
      top: 15px;
      left: 0;
      width: 44px;
      height: 44px;
      border-radius: 100%;
      background-color: #f7f7f7;
    }

    &:after {
      width: 26px;
      height: 24px;
      left: 15px;
      top: 22px;
      background: url("../images/list-checkmark.svg") top left no-repeat;
      background-size: contain;
    }
  }
}

.footer-block {
  @include clearfix;
  position: relative;
  max-width: 1090px;
  margin: 0 auto;
  background-color: $accent;
  color: #fff;
  padding: 40px 0 55px;
  margin-bottom: -20px;

  form {
    @include clearfix;
  }

  h2,
  label,
  .checkbox label,
  a,
  ::placeholder,
  .checkbox input:checked + label {
    color: #fff;
  }

  input[type="text"],
  input[type="search"],
  input[type="password"],
  input[type="email"],
  input[type="tel"],
  input[type="date"],
  textarea,
  select {
    background-color: $accent;
    color: #fff;

    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0px 1000px $accent inset;
      -webkit-text-fill-color: #fff;
    }
  }

  input[type="text"],
  input[type="search"],
  input[type="password"],
  input[type="email"],
  input[type="tel"],
  input[type="date"],
  textarea {
    border-bottom: 1px solid #fff;
  }

  fieldset + fieldset {
    margin-top: 40px;
  }

  h2 {
    @include fluid-type(font-size, $mediumSize, $largerSize, 24px, 30px);
    margin-bottom: 1.2em;
  }

  legend {
    text-transform: uppercase;
  }

  .button--white,
  input[type="submit"].button--white {
    color: $black;
  }

  @media #{$medium} {
    padding-top: 85px;
    margin-bottom: -80px;
  }
}

.ol-list {
  list-style: none;
  margin: 0 0 1.5em;
  counter-reset: myCounter;

  h3 {
    color: $black;
    @include fs(16);
    font-weight: 700;
    margin-bottom: 1.5em;
    margin-left: 50px;
  }

  li {
    position: relative;
    margin-bottom: 2.5em;
    counter-increment: myCounter;

    &:before {
      content: counter(myCounter);
      display: block;
      width: 44px;
      height: 44px;
      border-radius: 100%;
      position: absolute;
      top: -15px;
      left: 0;
      background-color: #f7f7f7;
      color: $accent;
      font-weight: 700;
      @include fs(20);
      text-align: right;
      padding: 0 10px;
      line-height: 44px;
    }
  }

  a {
    color: $accent;

    &:hover,
    &:focus {
      color: $accentHover;
    }
  }
}

/* =================================================
 U zoekt
   ================================================= */
.form-keep-me-updated {
  position: relative;
  margin-top: 30px;

  .grid {
    margin-bottom: 35px;
  }

  .switch {
    width: 100%;

    @media #{$medium} {
      margin-bottom: 20px;
    }
  }

  .range {
    max-width: inherit;
  }

  .range-main,
  .select2 {
    margin-bottom: 40px;
    border-bottom: 1px solid #cbcbcb;
  }

  .range-main__item {
    border-left: 0;
  }

  @media #{$maxMedium} {
    .range-main {
      width: 100%;
      margin-bottom: 20px;

      input {
        max-width: 100%;
      }
    }
  }

  @media #{$medium} {
    margin-top: 50px;
  }
}

.pos-rel {
  position: relative;
}

/* =================================================
 Estimate tool
   ================================================= */

.step-list {
  list-style: none;
  margin: 50px 0 0;
  counter-reset: step-counter;

  @media #{$medium} {
    margin-top: 20px;
    margin-bottom: 60px;
  }
}

.step-list--alt {
  counter-reset: step-counter 2;
}

.step-list__item {
  position: relative;
  margin-bottom: 40px;
  min-height: 164px;
  counter-increment: step-counter;
  display: flex;

  &:before {
    content: counter(step-counter) ".";
    display: block;
    position: absolute;
    top: 0;
    left: -70px;
    width: 160px;
    height: 160px;
    border-radius: 100%;
    background-color: #fafafa;
    z-index: -1;
    @include fluid-type(font-size, $mediumSize, $largerSize, 30px, 75px);
    font-weight: 800;
    color: $extraOrange;
    padding: 45px 0 0 70px;
    line-height: 1em;
  }

  @media #{$medium} {
    margin-bottom: 0px;
    min-height: 264px;

    &:before {
      left: -80px;
      width: 325px;
      height: 325px;
      padding: 85px 0 0 100px;
    }

    &:nth-child(even) {
      text-align: right;
      justify-content: flex-end;

      &:before {
        left: inherit;
        right: -80px;
        padding-left: 0;
        padding-right: 100px;
      }

      .step-list__content {
        padding-right: 110px;
        padding-left: 0;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }
    }
  }
}

.step-list__content {
  max-width: 700px;
  padding-left: 40px;
  padding-top: 40px;

  p {
    line-height: 1.9em;
  }

  @media #{$medium} {
    padding-top: 90px;
    padding-left: 110px;
  }
}

.step-list__title {
  font-weight: 800;
  @include fs(22);
  margin-bottom: 15px;
  color: $black;
  max-width: 410px;
  line-height: 1.4em;
}

.cta-group {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;

  .button + .button {
    margin-left: $gutter;
  }
}

// Cookie notification
.cookie-notification {
  position: fixed;
  bottom: $gutter / 2;
  left: $gutter / 2;
  right: $gutter / 2;
  z-index: 99999;
  display: flex;
  max-width: 1200px;
  background-color: $white;
  color: $black;
  transition: $transitionDefault;

  @media #{$medium} {
    left: $gutter;
    right: auto;
    bottom: $gutter;
    width: 90%;
  }
}

.cookie-notification__content {
  width: 100%;
  padding: 20px;
  overflow-y: auto;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  p:last-child {
    margin-bottom: 0;
  }

  .content--wrapper {
    > div:first-of-type {
      max-width: 650px;
      margin-right: $gutter / 2;
      margin-bottom: $gutter / 2;
    }

    @media #{$larger} {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;

      > div:first-of-type {
        margin-bottom: 0;
      }
    }
  }

  @media #{$medium} {
    max-height: calc(100vh - 40px);
    padding: $gutter;
  }
}

.cookie-notification__title {
  display: block;
  @include fs(20);
  font-weight: 600;
  line-height: 1.1em;
  margin-bottom: 0.5em;

  @media #{$medium} {
    @include fs(26);
  }
}

.cookie-notication__form {
  @include clearfix;
  margin: 0 0 $gutter;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  padding-bottom: 40px;

  label {
    @include fs(20);
  }

  .button {
    margin-top: $gutter;
  }

  .checkbox label {
    &:before {
      top: 6px;
    }

    &:after {
      top: 7px;
    }
  }

  .checkbox input {
    top: 6px;
  }

  .checkbox__description {
    @include fs(16);
  }
}

.cookie-notification__actions {
  margin-top: 0;
  margin-bottom: $gutter / 4;

  p,
  button {
    margin: 0;
  }

  p {
    margin-right: $gutter / 2;
    margin-bottom: $gutter / 2;
  }

  @media #{$large} {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    p {
      margin-bottom: 0;
    }
  }
}

.cookie-notification__close {
  background-color: transparent;
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 100;
  cursor: pointer;
  outline: 0;

  svg {
    fill: $white;
  }

  &:hover,
  &:focus {
    opacity: 0.8;
  }
}

/* =============================================================================
   PRINT
   Inlined to avoid required HTTP connection: h5bp.com/r
   ========================================================================== */
@media print {
  @page {
    margin: 2cm 0.5cm 1cm;
  }

  html {
    font-size: 10px;
  }

  * {
    background-color: transparent !important;
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
  }

  .ir {
    text-indent: 0;
    overflow: visible;
    direction: inherit;
  }

  a,
  a:visited {
    text-decoration: underline;
  }

  tr,
  img {
    page-break-inside: avoid;
  }
  img {
    max-width: 100% !important;
  }

  .page-header {
    position: absolute;
    padding-top: 0;
    height: 70px;
  }
  .main-nav__trigger {
    display: none;
  }

  .main {
    padding-top: 70px;
  }

  .container {
    padding-left: 0;
    padding-right: 0;
  }

  .page-footer,
  .main-nav,
  .property-agent + .box,
  .modal-nav-trigger,
  .page-actions,
  .property__links {
    display: none;
  }

  .slick-slide,
  .landscape-slideshow__slide,
  .landscape-slideshow__slide.slick-slide {
    opacity: 1;
  }

  .accordion__content {
    display: block;
  }

  .property__description,
  .box--large {
    @include fs(14);
  }

  .box {
    padding-left: 0;
    padding-right: 0;
  }

  .rte-arrows-list li {
    padding-left: 0;

    &:before {
      display: none;
    }
  }

  .dl-lined {
    margin-bottom: 10px;

    dt,
    dd {
      padding: 5px 0;
    }
  }

  .property__features-circle {
    width: auto;
    height: auto;
    padding: 0;
    margin: 0 0 30px;
  }

  .property-map__label {
    &:before {
      display: none;
    }
  }

  .medium--one-half {
    width: 50%;
  }
  .medium--two-thirds {
    width: 66.66%;
  }
  .medium--one-third {
    width: 33.33%;
  }
  .medium--three-quarters {
    width: 75%;
  }
  .medium--one-quarter {
    width: 25%;
  }

  .push--medium--one-quarter {
    left: 25%;
  }
}

#recente-panden {
  @media #{$maxMedium} {
    .inline-link-list {
      li,
      .button {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
      }
    }

    .jumbotron__content {
      padding-left: 2px;
      padding-right: 0;
    }
  }
}

.estimation-widget-wrapper {
  height: 650px;
  margin: 0 auto;

  @media #{$medium} {
    width: 80%;
    height: 700px;
  }
}

// .btn {
//   display: inline-flex;
//   align-items: center;
//   border: 1px solid black;
//   background: transparent;
//   color: black;
//   font-size: 16px;
//   font-family: serif;
//   font-size: 16px;
//   text-decoration: none;
//   padding: 1.2rem 1.7rem;
//   border-radius: 25px;

//   .dot {
//     width: 12px;
//     height: 12px;
//     background-color: #bc131a;
//     border-radius: 50%;
//     margin-right: 10px;
//   }
// }
