/*============================================================================
  VARIABLES
==============================================================================*/

// Grays
$black: #1e0606;
$grayDark: #333;
$gray: #b4b4b4;
$grayMiddle: #d2cecb;
$grayLight: #f7f7f7;
$white: #fff;

// Accent colors
$accent: #b90e0d;
$accentHover: lighten($accent, 10%);

$accentLight: lighten($accent, 15%);

$extraOrange: #ff6b4e;
$brown: #312320;

// Links
$linkColor: $black;
$linkColorHover: $accent;

// States
$error: #f1062c;
$errorBg: lighten($error, 50%);

$success: #39963a;
$successBg: lighten($success, 55%);

// Dot Dimensions
$dot-size: 2px;
$dot-space: 28px;
$dot-color: #bbbbbb;

// Selection
$selection: lighten($accent, 55%);

// Easing
$easeLinear: cubic-bezier(0, 0, 1, 1);
$easeIn: cubic-bezier(0.42, 0, 1, 1);
$easeOut: cubic-bezier(0, 0, 0.58, 1);
$easeInOut: cubic-bezier(0.42, 0, 0.58, 1);

// Transitions
$transitionDefault: 0.25s $easeOut;
$headerTransition: 0.45s $easeInOut;

// Fonts
$defaultFont: "greycliff-cf", Arial, Verdana, sans-serif;
$bureauSans: "STKBureauSans", Arial, Verdana, sans-serif;
$bureauSerif: "STKBureauSerif", "Georgia", "Times New Roman", "Times", serif;

// Sizes
$docFontSize: 10;

$borderRadius: 10px;
$borderRadiusMedium: 20px;
$borderRadiusLarge: 0;

// Grid
$gutter: 40px !default;

// Would you like push and pull classes enabled?
$push: true !default;
$pull: false !default;

// Mediaqueries
// If you want more breakpoints, add a nummeric suffix (e.g huge-2)
$smallSize: 375px;
$smallSize2: 480px;
$mediumSize: 768px;
$largeSize: 1024px;
$largerSize: 1200px;
$hugeSize: 1400px;
$massiveSize: 1680px;

$small: "(min-width: #{$smallSize})";
$small2: "(min-width: #{$smallSize2})";
$medium: "(min-width: #{$mediumSize})";
$mobile: "(max-width: #{$mediumSize})";
$large: "(min-width: #{$largeSize})";
$larger: "(min-width: #{$largerSize})";
$huge: "(min-width: #{$hugeSize})";
$massive: "(min-width: #{$massiveSize})";

$nav: "(min-width: 1024px)";

// Mobile mediaqueries
$maxMedium: "(max-width: 767px)";
$minSmallMaxMedium: "(min-width: #{$smallSize}) and (max-width: 766px)";

// Define your breakpoints. The first value is the prefix that shall be used for
// your classes (e.g. `.medium--one-half`), the second value is the media query
// that the breakpoint fires at.
$breakpoints: (
  "small" "#{$small}",
  "mobile" "#{$mobile}",
  "medium" "#{$medium}",
  "large" "#{$large}",
  "larger" "#{$larger}",
  "huge" "#{$huge}",
  "massive" "#{$massive}"
) !default;

// Define which namespaced breakpoints you would like to generate for each of
// widths, push and pull. This is handy if you only need pull on, say, large, or
// you only need a new width breakpoint at mobile sizes. It allows you to only
// compile as much CSS as you need.
$breakpoint-has-widths: (
  "small",
  "medium",
  "large",
  "larger",
  "huge",
  "massive"
) !default;
$breakpoint-has-push: ("medium", "large", "huge") !default;
$breakpoint-has-pull: ("medium") !default;

// Init mediaqueries for ordering inside carabiner
.init-mediaqueries {
  @media #{$small} {
    margin: 0;
  }
  @media #{$medium} {
    margin: 0;
  }
  @media #{$large} {
    margin: 0;
  }
  @media #{$larger} {
    margin: 0;
  }
  @media #{$huge} {
    margin: 0;
  }
  @media #{$massive} {
    margin: 0;
  }
}
